import { Box, Typography, Button, Modal, Stack, Input, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AlertMessage from '../../components/AlertMessage';
import {
  AWSAccessCredentials,
  AWSAccessRequest,
  AwsApiClient,
  AWSProductionAccessProfile,
  ParseError,
} from '../../api';

const PlainTextAccessCreds = ({
  creds,
  setInfo,
  setPlainText,
}: {
  creds: AWSAccessCredentials;
  setInfo: any;
  setPlainText: any;
}) => {
  const [showAccessKeyId, setShowAccessKeyId] = useState(false);
  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSessionToken, setShowSessionToken] = useState(false);
  const secrets = [
    {
      text: 'Access key',
      secret: creds.accessKeyId,
      show: showAccessKeyId,
      setShow: setShowAccessKeyId,
    },
    {
      text: 'Secret key',
      secret: creds.secretAccessKey,
      show: showAccessKey,
      setShow: setShowAccessKey,
    },
    {
      text: 'Session Token',
      secret: creds.sessionToken,
      show: showSessionToken,
      setShow: setShowSessionToken,
    },
  ];
  return (
    <Stack justifyContent="flex-start">
      <Button
        onClick={() => {
          setInfo('');
          setPlainText(false);
        }}
      >
        {' '}
        Back{' '}
      </Button>
      <Stack sx={{ pt: 1 }} justifyContent="center" alignItems="center">
        {secrets.map((s) => (
          <Stack>
            <Box>
              <Typography variant="h6" component="h2">
                {`${s.text}:`}
              </Typography>
            </Box>
            <Stack direction="row" spacing={2}>
              <Box sx={{ width: 200 }}>
                <Input type={s.show ? 'text' : 'password'} value={s.secret} readOnly fullWidth />
              </Box>
              <Box>
                <IconButton
                  aria-label={`Copy ${s.text}`}
                  onClick={() => {
                    navigator.clipboard.writeText(s.text);
                    setInfo(`${s.text} copied`);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <IconButton aria-label={`Show ${s.text}`} onClick={() => s.setShow(!s.show)}>
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const generateLinuxCreds = (creds: AWSAccessCredentials): string =>
  ` export AWS_ACCESS_KEY_ID="${creds.accessKeyId}"; export AWS_SECRET_ACCESS_KEY="${creds.secretAccessKey}"; export AWS_SESSION_TOKEN="${creds.sessionToken}"`;
const generateWindowsCreds = (creds: AWSAccessCredentials): string =>
  ` SET AWS_ACCESS_KEY_ID=${creds.accessKeyId}; SET AWS_SECRET_ACCESS_KEY=${creds.secretAccessKey};SET AWS_SESSION_TOKEN=${creds.sessionToken}`;
const generatePowerShellCreds = (creds: AWSAccessCredentials): string =>
  ` $Env:AWS_ACCESS_KEY_ID="${creds.accessKeyId}"; $Env:AWS_SECRET_ACCESS_KEY="${creds.secretAccessKey}"; $Env:AWS_SESSION_TOKEN="${creds.sessionToken}"`;

const copyText = ({ setInfo, text }: { setInfo: any; text: string }) => {
  navigator.clipboard.writeText(text);
  setInfo('Credentials copied');
};

const CLIAccess = ({
  tribeID,
  accessReqID,
  prodAccessProfileID,
  setGetCLIAccess,
}: {
  tribeID: string | number;
  accessReqID: number;
  prodAccessProfileID: number;
  setGetCLIAccess: any;
}) => {
  const [creds, setCreds] = useState<AWSAccessCredentials | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [plainText, setPlainText] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');

  useEffect(() => {
    AwsApiClient.getAccessCredentials(+tribeID, accessReqID, prodAccessProfileID)
      .then((res) => {
        setCreds(res.data);
      })
      .catch((err) => ParseError(err).then((e) => setError(e.error)))
      .finally(() => setLoading(false));
  }, [tribeID, accessReqID, prodAccessProfileID]);

  return (
    <>
      {loading && <Box> Loading </Box>}
      {info && <AlertMessage type="info"> {info} </AlertMessage>}
      {error && <AlertMessage type="error"> {error} </AlertMessage>}
      {creds && (
        <>
          {!plainText && (
            <Stack spacing={4}>
              <Button
                onClick={() => {
                  setInfo('');
                  setGetCLIAccess(false);
                }}
              >
                {' '}
                Back{' '}
              </Button>
              <Stack direction="row" justifyContent="space-around">
                <Button onClick={() => copyText({ setInfo, text: generateLinuxCreds(creds) })}>
                  {' '}
                  Linux/Mac{' '}
                </Button>
                <Button onClick={() => copyText({ setInfo, text: generateWindowsCreds(creds) })}>
                  {' '}
                  Windows{' '}
                </Button>
                <Button onClick={() => copyText({ setInfo, text: generatePowerShellCreds(creds) })}>
                  {' '}
                  PowerShell{' '}
                </Button>
              </Stack>
              <Button
                onClick={() => {
                  setInfo('');
                  setPlainText(true);
                }}
              >
                {' '}
                PlainText{' '}
              </Button>
            </Stack>
          )}
          {plainText && (
            <PlainTextAccessCreds creds={creds} setInfo={setInfo} setPlainText={setPlainText} />
          )}
        </>
      )}
    </>
  );
};

const ChoseProductionAccess = ({
  productionAccess,
  setChosenAccess,
}: {
  productionAccess: AWSProductionAccessProfile[];
  setChosenAccess: any;
}) => (
  <Stack>
    {productionAccess?.map((row) => (
      <Button key={row.ID} onClick={() => setChosenAccess(row)}>
        {`${row.name} - ${row.roleAlias}`}
      </Button>
    ))}
  </Stack>
);

const ChoseCliAccess = ({
  productionAccess,
  setChosenAccess,
  handleConsoleAccess,
  setGetCLIAccess,
}: {
  productionAccess: AWSProductionAccessProfile[];
  setChosenAccess: any;
  handleConsoleAccess: any;
  setGetCLIAccess: any;
}) => (
  <Stack>
    {productionAccess && productionAccess.length > 1 && (
      <Button onClick={() => setChosenAccess(undefined)}>Back</Button>
    )}
    <Stack direction="row" justifyContent="center">
      <Button onClick={() => handleConsoleAccess()} sx={{ width: '100%' }}>
        Console
      </Button>
      <Button onClick={() => setGetCLIAccess(true)} sx={{ width: '100%' }}>
        CLI
      </Button>
    </Stack>
  </Stack>
);

const UseAccessModal = ({
  request,
  modalState,
  setModalState,
}: {
  request: AWSAccessRequest;
  modalState: boolean;
  setModalState: (...args: any[]) => any;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState('');

  const [productionAccess, setProductionAccess] = useState<
    AWSProductionAccessProfile[] | undefined
  >();
  const [chosenAccess, setChosenAccess] = useState<AWSProductionAccessProfile | undefined>();

  const [getCLIAccess, setGetCLIAccess] = useState(false);

  useEffect(() => {
    AwsApiClient.getProductionAccesses(request.target_tribe_id)
      .then((res) => {
        setProductionAccess(res.data);
        if (res.data.length === 1) {
          setChosenAccess(res.data[0]);
        }
        setLoading(false);
      })
      .catch((err) => ParseError(err).then((e) => setError(e.error)));
  }, [request.target_tribe_id]);

  const handleModalClose = () => {
    setError('');
    setProductionAccess(undefined);
    setChosenAccess(undefined);
    setGetCLIAccess(false);
    setModalState(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleConsoleAccess = () => {
    setLoading(true);
    if (chosenAccess?.ID) {
      AwsApiClient.getAccessCredentials(request.target_tribe_id, request.ID, chosenAccess.ID)
        .then((getRes) => {
          AwsApiClient.createAWSConsoleURL(getRes.data)
            .then((createRes) => {
              window.open(createRes.data, '_blank', 'noopener,noreferrer');
            })
            .catch((_e) => setError(''));
        })
        .catch((err) => ParseError(err).then((e) => setError(e.error)));
    }
    setLoading(false);
  };

  return (
    <Modal open={modalState} onClose={() => handleModalClose()}>
      <Box sx={modalStyle}>
        {loading && <Box> Loading </Box>}
        {error && <AlertMessage type="error"> {error} </AlertMessage>}
        {!loading && (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!chosenAccess && productionAccess && (
                <ChoseProductionAccess
                  productionAccess={productionAccess}
                  setChosenAccess={setChosenAccess}
                />
              )}
              {chosenAccess && !getCLIAccess && productionAccess && (
                <ChoseCliAccess
                  productionAccess={productionAccess}
                  setChosenAccess={setChosenAccess}
                  handleConsoleAccess={handleConsoleAccess}
                  setGetCLIAccess={setGetCLIAccess}
                />
              )}
              {chosenAccess && getCLIAccess && (
                <CLIAccess
                  tribeID={request.target_tribe_id}
                  accessReqID={request.ID}
                  prodAccessProfileID={chosenAccess?.ID}
                  setGetCLIAccess={setGetCLIAccess}
                />
              )}
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

const UseAccessButton = ({
  request,
  showUnapproved = true,
}: {
  request: AWSAccessRequest;
  showUnapproved?: boolean;
}) => {
  const [modalState, setModalState] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {request.approved && (
        <>
          <Button size="small" onClick={() => setModalState(true)}>
            View
          </Button>
          {modalState && (
            <UseAccessModal
              modalState={modalState}
              setModalState={setModalState}
              request={request}
            />
          )}
        </>
      )}
      {!request.approved && showUnapproved && (
        <Button
          variant="outlined"
          onClick={() =>
            navigate(`/tribes/${request.target_tribe_id}/aws/access_requests/${request.ID}`)
          }
        >
          {' '}
          View request{' '}
        </Button>
      )}
    </>
  );
};

export default UseAccessButton;
