import { Typography } from "@mui/material";

const LegalText = () => (
  <>
    <Typography>
      Before being granted access to this sensitive information, it is crucial to fully understand
      and comply with the legal and ethical responsibilities associated with handling such data.
      Please carefully read and acknowledge the following terms and conditions:
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>1. Confidentiality and Non-Disclosure:</b> By gaining access to the production
      database, you agree to maintain strict confidentiality and not disclose any
      customers&apos; data or related information to unauthorised individuals or entities. This
      includes, but is not limited to, personally identifiable information (PII), financial
      details, contact information, or any other data that could potentially identify a
      customer.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>2. Data Usage Limitations:</b> Access to the production database is solely granted for
      the purpose of fulfilling your authorised duties and responsibilities. You are prohibited
      from using the customers&apos; data for any other purposes, including personal or
      unauthorised commercial use.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>3. Data Protection and Security:</b> It is your responsibility to ensure the security
      and protection of the customers&apos; data throughout the entire access and usage process.
      Any unauthorised use, data breaches, or suspicious activities must be reported immediately
      to the appropriate personnel or authorities.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>4. Compliance with Applicable Laws and Regulations:</b> You must adhere to all relevant
      laws, regulations, and industry standards pertaining to data protection, privacy, and
      security. This includes, but is not limited to, compliance with the General Data
      Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and any other
      applicable local, regional, or international laws.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>5. Limited Access and Need-to-Know Principle:</b> Access to the production database
      will be granted on a need-to-know basis. You are only authorised to access and retrieve
      customers&apos; data that is essential for the completion of your assigned tasks. Any
      attempts to access or modify data beyond the scope of your authorised responsibilities are
      strictly prohibited.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>6. Record Keeping and Audit Trail:</b> All actions performed within the production
      database must be recorded and maintained for audit purposes. This includes logins,
      queries, modifications, and any other relevant activities. The database administrators
      reserve the right to monitor and review these records at their discretion.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>7. Accountability and Liability:</b> By requesting access to the production database,
      you acknowledge that you will be held accountable for any breaches, misuse, or
      unauthorised access to customers&apos; data that occur under your account. You agree to
      assume full responsibility and potential legal liabilities resulting from non-compliance
      with the terms and conditions outlined herein.
    </Typography>
    <Typography sx={{ pl: 4 }}>
      <b>8. Termination of Access:</b> Access to the production database may be terminated or
      suspended at any time without prior notice if there is a breach of the terms and
      conditions, or if it is determined that access is no longer necessary for the performance
      of your duties.
    </Typography>
    <Typography>
      By proceeding with your access request, you indicate that you have read, understood, and
      agree to abide by the terms and conditions outlined in this warning notice. Failure to
      comply with these terms may result in disciplinary action, termination of access, and
      potential legal consequences.
    </Typography>
  </>
);
export default LegalText
