import { Grid, Box, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../components/InfoCard';
import UseAccessButton from './use_access';
import { AuthUserApiClient, AWSAccessRequest, TribeApiClient, Tribe } from '../../api';

const ActiveAwsRequests = () => {
  const [accessRequests, setAccessRequests] = useState<AWSAccessRequest[] | undefined>();
  useEffect(() => {
    AuthUserApiClient.getMyAccessRequests()
      .then((res) => setAccessRequests(res.data))
      .catch(() => console.error('Failed to get authenticated user DB access requests'));
  }, []);
  return (
    <Box>
      {accessRequests && accessRequests.length > 0 && (
        <>
          <Typography component="h2" variant="h6" sx={{ m: '1rem' }} gutterBottom>
            My Active requests
          </Typography>
          <List dense sx={{ width: '40%', maxWidth: '900px', bgcolor: 'background.paper' }}>
            {accessRequests.map((row) => (
              <ListItem
                key={row.ID}
                secondaryAction={<UseAccessButton request={row} />}
                sx={{ mb: '1rem' }}
              >
                {row.approved ? (
                  <ListItemText>
                    Access to the{' '}
                    <Box
                      component="span"
                      fontWeight="fontWeightBold"
                    >{`${row.target_tribe.tribeName}`}</Box>{' '}
                    account is available until {`${row.valid_until}`}
                  </ListItemText>
                ) : (
                  <ListItemText>
                    Access to the{' '}
                    <Box
                      component="span"
                      fontWeight="fontWeightBold"
                    >{`${row.target_tribe.tribeName}`}</Box>{' '}
                    account for {`${row.valid_for}`} is yet to be approved
                  </ListItemText>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

const AWSAccountGrid = () => {
  const [tribes, setTribes] = useState<Tribe[] | undefined>();
  useEffect(() => {
    TribeApiClient.getTribes(true)
      .then((res) => setTribes(res.data.tribes))
      .catch(() => console.error('Failed to fetch tribes list'));
  }, []);
  return (
    <>
      <Typography component="h2" variant="h6" sx={{ m: '1rem' }} gutterBottom>
        Get RW access to production
      </Typography>
      {tribes?.length === 0 && (
        <Typography component="h2" variant="h6" sx={{ m: '1rem' }} gutterBottom>
          You are not in any tribes, to get request access to tribe you need first to be a member on
          it
        </Typography>
      )}
      {tribes && (
        <Box sx={{ mx: '1rem' }}>
          <Grid container spacing={1}>
            {tribes.map((tribe) => (
              <InfoCard
                title={`${tribe.tribeName} Production account`}
                subheader={`You are a member of the ${tribe.tribeName} tribe, and have permissions to access production account. You can request temporary access to production account.`}
                buttonText="Get access"
                buttonSx={{ width: '100%' }}
                width={4}
                path={`/aws/new_access/${tribe.id}`}
                key={`tribe-${tribe.id}`}
              />
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

const AWSIndex = () => (
  <Box>
    <ActiveAwsRequests />
    <Divider />
    <AWSAccountGrid />
  </Box>
);
export default AWSIndex;
