/* eslint-disable import/prefer-default-export */

import { AwsApi, DbApi, MyApi, TribeApi, UserApi } from './api';
import { Configuration } from './configuration';

const config: Configuration = { basePath: '' };
export const AuthUserApiClient: MyApi = new MyApi(config);
export const AwsApiClient: AwsApi = new AwsApi(config);
export const DbApiClient: DbApi = new DbApi(config);
export const TribeApiClient: TribeApi = new TribeApi(config);
export const UserApiClient: UserApi = new UserApi(config);

export interface ErrorObject extends Error {
  errors: any;
  error: string;
  code: number;
}
const INTERNAL_SERVER_ERROR_STATUS = 500;

const defaultErrorObject: ErrorObject = {
  name: 'Error',
  message: 'Error in API call',
  error: 'Failed to parse error response',
  errors: {
    general: {
      key: 'errorInternalServerErrorEmpty',
      message: 'Internal server error.',
    },
    fields: [],
  },
  code: INTERNAL_SERVER_ERROR_STATUS,
};

export function ParseError(err: Response): Promise<ErrorObject> {
  if (!err) return Promise.resolve(defaultErrorObject);
  return err
    .json()
    .catch(() => defaultErrorObject)
    .then((error) => {
      const errorObject: ErrorObject = {
        name: 'Error',
        message: 'Error in API call',
        error: error.error,
        errors: error.errors,
        code: error.code,
      };

      return errorObject;
    });
}
