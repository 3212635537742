import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonSearchIcon from '@mui/icons-material/Search';
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable';
import Title from '../../components/Title';
import AddUserButton from './addUserButton';
import UserPermCheckboxList from './userPermCheckboxList';
import { UserContext } from '../../context/UserContext';
import {
  InlineResponse2006,
  TribeApiClient,
  Pagination as ApiPagination,
  InlineResponse2007Data,
  User,
  InlineResponse2006Pagination,
  UserApiClient,
  TribePermissions,
  TribeIDMembersBody,
  Tribe,
  ParseError,
} from '../../api';

const searchBarTheme = createTheme({
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            '.MuiInputLabel-root': {
              color: grey[600],
            },
            '.MuiInputLabel-root.Mui-focused': {
              color: grey[600],
            },
            '.MuiInput-root:after': {
              borderBottom: 0,
            },
            '.MuiInput-root': {
              borderBottom: `2px solid ${grey[600]}`,
            },
            '.MuiInput-input': {
              color: `${grey[400]}`,
            },
          },
        },
      ],
    },
  },
});
const MemberRow = (props: {
  member: User;
  setMembers: React.Dispatch<React.SetStateAction<User[]>>;
  level: string;
  tribeId: number;
  showEditPerm: boolean;
  setPagination: React.Dispatch<React.SetStateAction<ApiPagination | undefined>>;
}) => {
  const { member, setMembers, level, tribeId, showEditPerm, setPagination } = props;
  const [open, setOpen] = React.useState(false);

  const handleRemoveMemberBtnClick = () => () => {
    TribeApiClient.removeTribeMember(tribeId, member.id)
      .then((_) => {
        TribeApiClient.getTribeMembers(
          tribeId,
          1,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          [level]
        )
          .then((res) => {
            const membersAtLevel = res.data[level as keyof InlineResponse2007Data] ?? [];
            setMembers(membersAtLevel);
            if (res.pagination) {
              const pagination = res.pagination[level as keyof InlineResponse2006Pagination];
              setPagination(pagination);
            }
          })
          .catch(() => console.error('Failed to fetch tribe members'));
      })
      .catch(() => console.error('Failed to remove user from tribe'));
  };

  return (
    <>
      <StyledTableRow key={member.id}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={12}>
            <p>{member.email}</p>
            {showEditPerm && (
              <IconButton aria-label="remove member" onClick={handleRemoveMemberBtnClick()}>
                <PersonRemoveIcon />
              </IconButton>
            )}
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <UserPermCheckboxList tribeId={tribeId} userId={member.id} canEdit={showEditPerm} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const MembersTable = (props: {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  level: string;
  showEditPerm: boolean;
  tribeId: number;
  setPagination: React.Dispatch<React.SetStateAction<ApiPagination | undefined>>;
}) => {
  const { users, setUsers, level, showEditPerm, tribeId, setPagination } = props;
  const primaryColor: string = grey[400];
  const secondaryColor: string = grey[600];
  const [searchBtnColor, setSearchBtnColor] = useState<string>(primaryColor);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const handleSearchBtnClick = () => {
    const color: string = searchBtnColor === primaryColor ? secondaryColor : primaryColor;
    setSearchBtnColor(color);
    setShowSearchBar((show) => !show);
  };

  const handleSearchInputChange = (event: any) => {
    if (event.key === 'Enter') {
      TribeApiClient.getTribeMembers(
        tribeId,
        1,
        undefined,
        undefined,
        event.target.value,
        undefined,
        undefined,
        [level]
      )
        .then((res) => {
          const membersAtLevel = res.data[level as keyof InlineResponse2007Data] ?? [];
          setUsers(membersAtLevel);
          if (res.pagination) {
            const p: ApiPagination = res.pagination[level as keyof InlineResponse2006Pagination];
            setPagination(p);
          }
        })
        .catch(() => console.error('Failed to get tribe members'));
    }
  };

  return (
    <TableContainer component={Paper} sx={{}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 50 }} />
            <StyledTableCell>
              <Box display="flex">
                <Box width={0.3}>
                  <p>User Email</p>
                </Box>
                <Box width={0.7} display="flex" flexDirection="row-reverse">
                  <Collapse orientation="horizontal" in={showSearchBar}>
                    <ThemeProvider theme={searchBarTheme}>
                      <TextField
                        id={`search-${level}-input`}
                        label="User email address"
                        variant="standard"
                        onKeyDown={handleSearchInputChange}
                      />
                    </ThemeProvider>
                  </Collapse>
                  <IconButton aria-label="search-user" onClick={handleSearchBtnClick}>
                    <PersonSearchIcon sx={{ color: searchBtnColor }} />
                  </IconButton>
                </Box>
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((row) => (
            <MemberRow
              tribeId={tribeId}
              showEditPerm={showEditPerm}
              member={row}
              level={level}
              setMembers={setUsers}
              setPagination={setPagination}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type RequestParams = {
  id: string;
};

const TribesID = () => {
  const { id } = useParams<RequestParams>();
  const { authenticatedUser, isLoading } = useContext(UserContext);
  const [tribe, setTribe] = useState<Tribe>();
  const [owners, setOwners] = useState<User[]>([]);
  const [developers, setDevelopers] = useState<User[]>([]);
  const [allowed, setAllowed] = useState(false);
  const [ownersPagination, setOwnersPagination] = useState<ApiPagination>();
  const [devsPagination, setDevsPagination] = useState<ApiPagination>();
  const { data, status } = useQuery<InlineResponse2006>({
    queryKey: ['getTribe', id],
    queryFn: () => {
      if (id) return TribeApiClient.getTribe(+id);
      return Promise.resolve({} as InlineResponse2006);
    },
    enabled: true,
    retry: 3,
    retryDelay: (attempt) => attempt * 1000, // linear backoff
    refetchInterval: 300000, // 5 min
    refetchIntervalInBackground: true,
  });
  const userPerms = useQuery<TribePermissions>({
    queryKey: ['getUserPermsOnTribe', authenticatedUser.id, id],
    queryFn: () => {
      if (!isLoading && id)
        return UserApiClient.getUserPermOnTribes(authenticatedUser.id, +id).then((res) => res.data);
      return Promise.resolve({} as TribePermissions);
    },
    enabled: true,
    retry: 3,
    retryDelay: (attempt) => attempt * 1000, // linear backoff
    refetchInterval: 300000, // 5 min
    refetchIntervalInBackground: true,
  });

  function getPaginationCount(level: string): number {
    const pagination = level === 'owner' ? ownersPagination : devsPagination;
    if (!pagination) return 0;
    return pagination.total_records;
  }

  // The zero-based index of the current page
  function getCurrentPage(level: string): number {
    const pagination = level === 'owner' ? ownersPagination : devsPagination;
    if (!pagination) return 0;
    return pagination.current_page === 0 ? 0 : pagination.current_page - 1;
  }

  function getPageSize(level: string): number {
    const pagination = level === 'owner' ? ownersPagination : devsPagination;
    if (!pagination) return 0;
    return pagination.size;
  }

  const getMembersPage = (level: string) => (_event: React.MouseEvent | null, page: number) => {
    if (tribe) {
      const pagination = level === 'owner' ? ownersPagination : devsPagination;
      TribeApiClient.getTribeMembers(
        tribe.id,
        page + 1,
        pagination?.size,
        undefined,
        undefined,
        undefined,
        undefined,
        [level]
      )
        .then((res) => {
          const membersAtLevel = res.data[level as keyof InlineResponse2007Data] ?? [];
          if (level === 'owner') setOwners(membersAtLevel);
          else setDevelopers(membersAtLevel);
          if (res.pagination) {
            if (level === 'owner') setOwnersPagination(res.pagination.owner);
            else setDevsPagination(res.pagination.developer);
          }
        })
        .catch(() => console.error('Failed to get tribe members'));
    }
  };

  const handleChangeRowsPerPage = (level: string) => (event: React.ChangeEvent<any>) => {
    if (tribe) {
      TribeApiClient.getTribeMembers(
        tribe.id,
        1,
        event.target.value,
        undefined,
        undefined,
        undefined,
        undefined,
        [level]
      )
        .then((res) => {
          const membersAtLevel = res.data[level as keyof InlineResponse2007Data] ?? [];
          if (level === 'owner') setOwners(membersAtLevel);
          else setDevelopers(membersAtLevel);
          if (res.pagination) {
            if (level === 'owner') setOwnersPagination(res.pagination.owner);
            else setDevsPagination(res.pagination.developer);
          }
        })
        .catch(() => console.error('Failed to get tribe members'));
    }
  };

  useEffect(() => {
    if (data) {
      setTribe(data.data.tribe);
      setOwners(data.data.tribe.owners);
      setDevelopers(data.data.tribe.developers);
      const { pagination } = data;
      setOwnersPagination(pagination.owner);
      setDevsPagination(pagination.developer);
    }

    if (userPerms.data) {
      if (userPerms.data.canUserManageTribe) {
        setAllowed(userPerms.data.canUserManageTribe);
      }
    }
  }, [data, userPerms.data]);

  if (!isLoading && status === 'success' && tribe) {
    return (
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
            gap: '1rem',
          }}
        >
          <Typography component="h2" variant="h6" sx={{ mx: '1rem' }} gutterBottom>
            {tribe.tribeName}
          </Typography>
        </Box>
        <Container>
          <Title> Owners </Title>
          <MembersTable
            users={owners}
            setUsers={setOwners}
            level="owner"
            tribeId={tribe.id}
            showEditPerm={false}
            setPagination={setOwnersPagination}
          />
          {authenticatedUser.role === User.RoleEnum.Administrator && (
            <Grid container justifyContent="flex-end">
              <AddUserButton tribeId={`${tribe.id}`} level={TribeIDMembersBody.LevelEnum.Owner} />
            </Grid>
          )}
          <Grid container justifyContent="center">
            <TablePagination
              component="div"
              count={getPaginationCount('owner')}
              size="small"
              page={getCurrentPage('owner')}
              onPageChange={getMembersPage('owner')}
              rowsPerPage={getPageSize('owner')}
              onRowsPerPageChange={handleChangeRowsPerPage('owner')}
              rowsPerPageOptions={[5, 10, 15]}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Container>
        <Container sx={{ marginTop: '2rem' }}>
          <Title> Developers </Title>
          <MembersTable
            users={developers}
            setUsers={setDevelopers}
            level="developer"
            tribeId={tribe.id}
            showEditPerm={allowed}
            setPagination={setDevsPagination}
          />
          {allowed && (
            <Grid container justifyContent="flex-end">
              {' '}
              <AddUserButton
                tribeId={`${tribe.id}`}
                level={TribeIDMembersBody.LevelEnum.Developer}
              />{' '}
            </Grid>
          )}
          <Grid container justifyContent="center">
            <TablePagination
              component="div"
              count={getPaginationCount('developer')}
              page={getCurrentPage('developer')}
              onPageChange={getMembersPage('developer')}
              rowsPerPage={getPageSize('developer')}
              onRowsPerPageChange={handleChangeRowsPerPage('developer')}
              rowsPerPageOptions={[5, 10, 15]}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Container>
      </div>
    );
  }
  return <p> Loading... </p>;
};
export default TribesID;
