import * as React from 'react';
import Typography from '@mui/material/Typography';

interface TitleProps {
    children?: React.ReactNode;
}

const Title = ({ children }: TitleProps) =>
(
    <Typography component="h2" variant="h6" color="primary" sx={{ mx: '1rem' }} gutterBottom >
        {children}
    </Typography>
);

export default Title;
