import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  DBAccessRequest,
  DbApiClient,
  InlineResponse20014,
  Pagination as ApiPagination,
} from '../../api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const RequestsTable = () => {
  const [page, setPage] = useState<number>(0); // TablePagination starts numbering from 0
  const [pageSize, setPageSize] = useState<number>(5);
  const [pagination, setPagination] = useState<ApiPagination>();
  const getRequestsRes = useQuery<InlineResponse20014>({
    queryKey: ['getRequests', page, pageSize],
    queryFn: () => DbApiClient.getRequests(page, pageSize),
    enabled: true,
    retry: 3,
    retryDelay: (attempt) => attempt * 1000, // linear backoff
    refetchInterval: 300000, // 5 min
    refetchIntervalInBackground: true,
  });
  const navigate = useNavigate();

  function getPaginationCount(): number {
    if (!pagination) return 0;
    return pagination.total_records;
  }

  const handlePageChange = (_event: React.MouseEvent | null, p: number) => {
    setPage(p);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<any>) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const res = getRequestsRes.data;
    setPagination(res?.pagination as ApiPagination);
  }, [getRequestsRes.data]);

  if (getRequestsRes.data?.data.requests) {
    return (
      <Container
        component="main"
        id="main"
        sx={{
          paddingX: 8,
          paddingY: 3,
          margin: 0,
          flexGrow: 1,
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Request Target</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">User Email</StyledTableCell>
                <StyledTableCell align="right">AcceptedBy</StyledTableCell>
                <StyledTableCell align="right">Valid until/for</StyledTableCell>
                <StyledTableCell align="right">View Request</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getRequestsRes.data.data.requests?.map((row) => (
                <StyledTableRow key={row.ID}>
                  <StyledTableCell component="th" scope="row">
                    {row.Target}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.Status}</StyledTableCell>
                  <StyledTableCell align="right">{row.User.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.AcceptedBy}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.Status === DBAccessRequest.StatusEnum.Requested
                      ? row.ValidFor
                      : row.ValidUntil.toString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button size="small" onClick={() => navigate(`/requests/${row.ID}`)}>
                      View
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="center">
          <TablePagination
            component="div"
            count={getPaginationCount()}
            size="small"
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15]}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Container>
    );
  }
  return (
    <div className="App">
      {getRequestsRes.status === 'error' && (
        <Typography variant="body1" sx={{ m: '1rem' }} gutterBottom>
          Error fetching data
        </Typography>
      )}
      {getRequestsRes.status === 'loading' && (
        <Typography variant="body1" sx={{ m: '1rem' }} gutterBottom>
          Fetching data...
        </Typography>
      )}
    </div>
  );
};

export default RequestsTable;
