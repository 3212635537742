import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LegalText from '../../components/legalText';
import { UserContext } from '../../context/UserContext';
import { TribeApiClient, Tribe, AwsApiClient, ParseError } from '../../api';

type RequestParams = {
  id: string;
};

const NewAwsAccess = () => {
  const { id } = useParams<RequestParams>();
  const [tribe, setTribe] = useState<Tribe>();

  useEffect(() => {
    if (!id) return;
    TribeApiClient.getTribe(+id)
      .then((res) => {
        setTribe(res.data.tribe);
      })
      .catch(() => console.error('Failed to fetch tribes list'));
  }, [id]);

  const { authenticatedUser, isLoading } = useContext(UserContext);

  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [autoApprove, setAutoApprove] = useState(false);

  const navigate = useNavigate();

  const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setReason(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tribe) {
      AwsApiClient.createAWSAccessRequest(
        { tribe_id: tribe.id, reason, validFor: '4h', autoApprove },
        tribe.id
      )
        .then(() => navigate('/aws/'))
        .catch((err) => ParseError(err).then((errObj) => setError(errObj.error)));
    }
  };

  const handleChangeAutoApprove = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAutoApprove(e.target.checked);

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 4,
      }}
    >
      {error && (
        <Paper sx={{ p: 2 }}>
          {' '}
          <Typography variant="h2"> {error} </Typography>{' '}
        </Paper>
      )}
      <Paper sx={{ p: 2 }}>
        {isLoading && !tribe ? (
          'Loading...'
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
            <Typography variant="h2">{authenticatedUser.email} &nbsp;</Typography>
            <Typography variant="h5">
              you are creating temporary access to read and write to the &nbsp;
            </Typography>
            <Typography variant="h2">{`${tribe?.tribeName} tribe`}</Typography>
          </Box>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            id="reason"
            multiline
            placeholder="Why you need or want this access?"
            fullWidth
            autoFocus
            sx={{ py: 2 }}
            onChange={handleChangeReason}
          />
          <Paper sx={{ p: 2 }}>
            <Typography variant="h3">Please read it carefully:</Typography>
            <Box>
              <LegalText />
            </Box>
          </Paper>

          <FormControlLabel
            label="Auto Approve request?"
            control={
              <Checkbox
                checked={autoApprove}
                onChange={handleChangeAutoApprove}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />

          <Button
            variant="contained"
            color="warning"
            sx={{
              marginTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
            }}
            type="submit"
          >
            I am aware and I will be careful
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default NewAwsAccess;
