import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Logo } from '../img/locked-logo-small.svg';

import ProfileMenu from './ProfileMenu';
import { UserContext } from '../context/UserContext';
import { initials } from '../helpers/helpers';
import AppsMenu from './AppsMenu';
import { UIContext } from '../context/UIContext';
import TopBar from './TopBar';
import { NAV_CLOSED_DRAWER_WIDTH, NAV_DRAWER_WIDTH } from '../constants';

const HIDE_USER_PROFILE = false;
const HIDE_APP_DRAWER = false;

const TopNav = () => {
  const { authenticatedUser, isLoading } = useContext(UserContext);

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
  const profileMenuOpen = Boolean(profileAnchorEl);
  const [appAnchorEl, setAppAnchorEl] = useState<null | HTMLElement>(null);
  const appMenuOpen = Boolean(appAnchorEl);
  const { openSideNav, toggleDrawer, isMobile } = useContext(UIContext);

  return (
    <TopBar drawerWidth={NAV_DRAWER_WIDTH} closedDrawerWidth={NAV_CLOSED_DRAWER_WIDTH}>
      <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isMobile && (
            <IconButton
              sx={{
                padding: { xs: '12px', sm: '16px' },
                margin: '0 4px',
              }}
              onClick={() => toggleDrawer?.(!openSideNav)}
              aria-label="open drawer"
              disabled={openSideNav}
            >
              {openSideNav ? <MenuOpenOutlinedIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <Box
            sx={{
              display: { xs: 'flex', sm: openSideNav ? 'none' : 'flex' },
            }}
          >
            <Link
              href="/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Go To EposNowHQ"
              height="1.5em"
            >
              <SvgIcon inheritViewBox sx={{ width: '32px', height: '25px' }}>
                <Logo />
              </SvgIcon>
            </Link>
          </Box>
          <Link
            component={RouterLink}
            underline="none"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            to={'/' as any}
            color="inherit"
            sx={{
              marginLeft: { xs: '8px', sm: openSideNav ? 0 : '32px' },
            }}
          >
            <Typography variant="h4" noWrap color="text.primary">
              Platform Console
            </Typography>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* {!HIDE_APP_DRAWER && <IconButton
              sx={{
                padding: {xs: '12px', sm: '16px'},
                marginRight: '8px',
                ':hover': {
                  backgroundColor: (theme) => theme.palette.grey[100],
                },
                ...(appMenuOpen && {
                  backgroundColor: (theme) => theme.palette.grey[100],
                  color: 'primary.main',
                }),
              }}
              aria-label="open apps menu"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAppAnchorEl(event.currentTarget);
              }}
          >
            <AppsOutlinedIcon/> 
          </IconButton>} */}
          {!HIDE_USER_PROFILE && (
            <Button
              aria-label="open profile menu"
              variant="outlined"
              size="small"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setProfileAnchorEl(event.currentTarget);
              }}
              sx={{
                maxWidth: { xs: '36px', sm: '40px' },
                maxHeight: { xs: '36px', sm: '40px' },
                minWidth: { xs: '36px', sm: '40px' },
                minHeight: { xs: '36px', sm: '40px' },
                borderRadius: '20px',
                color: 'text.disabled',
                borderColor: 'text.disabled',
                borderWidth: '2px',
                ...(profileMenuOpen && {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  border: 'none',
                }),
                ':hover': {
                  borderColor: { sm: 'text.disabled' },
                  backgroundColor: (theme) => theme.palette.grey[100],
                  border: { xs: 'none', sm: '2px solid' },
                  ...(profileMenuOpen && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                  }),
                },
              }}
            >
              <Typography
                sx={{
                  padding: { xs: '12px 15px', sm: '16px 19px' },
                  borderRadius: '40px',
                  ':hover': {
                    ...(!profileMenuOpen && {
                      backgroundColor: (theme) => theme.palette.grey[100],
                    }),
                  },
                  ...(profileMenuOpen && {
                    backgroundColor: (theme) => theme.palette.grey[100],
                  }),
                }}
              >
                {!isLoading && authenticatedUser?.email
                  ? initials(`${authenticatedUser?.email}`)
                  : '···'}
              </Typography>
            </Button>
          )}
          {!HIDE_USER_PROFILE && (
            <ProfileMenu
              open={profileMenuOpen}
              anchorEl={profileAnchorEl}
              handleClose={() => setProfileAnchorEl(null)}
            />
          )}
          {!HIDE_APP_DRAWER && (
            <AppsMenu
              open={appMenuOpen}
              anchorEl={appAnchorEl}
              handleClose={() => setAppAnchorEl(null)}
            />
          )}
        </Box>
      </>
    </TopBar>
  );
};
export default TopNav;
