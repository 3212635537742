import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './screens/home/Home';
import NotFound from './screens/error/NotFound';
import Requests from './screens/requests/Requests';
import NewRequest from './screens/requests/New';
import RequestID from './screens/requests/id';
import Tribes from './screens/tribes/index';
import TribesID from './screens/tribes/id';
import AWSIndex from './screens/aws';
import NewAwsAccess from './screens/aws/new';
import AwsAccessRequestId from './screens/aws/access_request_id';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/requests" element={<Requests />} />
    <Route path="/requests/create/:target" element={<NewRequest />} />
    <Route path="/requests/:id" element={<RequestID />} />
    <Route path="/tribes" element={<Tribes />} />
    <Route path="/tribes/:id" element={<TribesID />} />
    <Route path="/aws" element={<AWSIndex />} />
    <Route path="/aws/new_access/:id" element={<NewAwsAccess />} />
    <Route path="/tribes/:tribeID/aws/access_requests/:id" element={<AwsAccessRequestId />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
