import React from 'react';
import { Divider, Typography, Box } from '@mui/material';
import ActiveRequests from './ActiveRequests';
import RequestsTable from './RequestsTable';

const Requests = () => (
  <>
    <ActiveRequests />
    <Divider />
    <Typography variant="h6" sx={{ m: '1rem' }} gutterBottom>
      History
    </Typography>
    <Box p="0" display="flex" alignItems="center" justifyContent="center">
      <RequestsTable />
    </Box>
  </>
);

export default Requests;
