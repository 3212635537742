import { Modal, Box, Button, TextField, IconButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AlertMessage from '../../components/AlertMessage';
import { ParseError, TribeApiClient, TribeIDMembersBody } from '../../api';

interface AddUserButtonInterface {
  tribeId: string;
  level: TribeIDMembersBody.LevelEnum;
}

interface AddUserModalInterface {
  modalState: boolean;
  tribeId: string;
  level: TribeIDMembersBody.LevelEnum;
  setModalState: (...args: any[]) => any;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddUserModal = ({ modalState, setModalState, tribeId, level }: AddUserModalInterface) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleChangeEmail = (e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    TribeApiClient.addTribeMember(+tribeId, { email, level })
      .then((_) => {
        setEmail('');
        setError('');
        setModalState(false);
        navigate(0);
      })
      .catch((err) => ParseError(err).then((errObj) => setError(errObj.error)));
  };

  return (
    <Modal
      open={modalState}
      onClose={() => {
        setModalState(false);
        setError('');
      }}
    >
      <Box sx={modalStyle}>
        {error && <AlertMessage type="error"> {error} </AlertMessage>}
        <form onSubmit={handleSubmit}>
          <TextField
            id="email"
            multiline
            placeholder="User's exact email"
            fullWidth
            autoFocus
            sx={{ py: 2 }}
            onChange={handleChangeEmail}
          />
          <Button
            variant="contained"
            color="warning"
            sx={{
              marginTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
            }}
            type="submit"
          >
            Add User
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

const AddUserButton = ({ tribeId, level }: AddUserButtonInterface) => {
  const [modalState, setModalState] = useState(false);

  return (
    <>
      <IconButton sx={{ m: 1.5 }} aria-label="add member" onClick={() => setModalState(true)}>
        <PersonAddAlt1Icon />
      </IconButton>
      <AddUserModal
        modalState={modalState}
        setModalState={setModalState}
        tribeId={tribeId}
        level={level}
      />
    </>
  );
};

export default AddUserButton;
