import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import AlertMessage from '../../components/AlertMessage';
import { ParseError, TribePermissions, UserApiClient } from '../../api';

interface Permission {
  id: number;
  name: string;
  value: boolean;
}

const defaultUserPermissions: Permission[] = [
  // { id: 0, name: 'User can manage tribe', value: false },
  { id: 1, name: 'Access to tribe production accounts', value: false },
];

// Map array of Permission to GetUserPermsOnTribeResponse object
const mapPermissions = (permissions: Permission[]): TribePermissions => {
  const userPermissionsOnTribe: TribePermissions = {
    canUserManageTribe: false,
    canUserAccessProduction: false,
  };
  permissions.forEach((permission) => {
    switch (permission.id) {
      case 0:
        userPermissionsOnTribe.canUserManageTribe = permission.value;
        break;
      case 1:
        userPermissionsOnTribe.canUserAccessProduction = permission.value;
        break;
      default:
        break;
    }
  });

  return userPermissionsOnTribe;
};

// Update permission in array of permissions, by permission ID
const updatePermission = (permissions: Permission[], id: number, newValue: boolean): Permission[] =>
  permissions.map((permission) => {
    if (permission.id === id) {
      return { ...permission, value: newValue };
    }
    return permission;
  });

const UserPermCheckboxList = (props: { tribeId: number; userId: number; canEdit: boolean }) => {
  const { tribeId, userId, canEdit } = props;
  const [permissions, setPermissions] = useState(defaultUserPermissions);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState('');

  useEffect(() => {
    UserApiClient.getUserPermOnTribes(userId, tribeId)
      .then((res) => {
        let newPermissions = [...permissions];
        if (res.data.canUserManageTribe !== undefined) {
          newPermissions = updatePermission(newPermissions, 0, res.data.canUserManageTribe);
        }
        if (res.data.canUserAccessProduction !== undefined) {
          newPermissions = updatePermission(newPermissions, 1, res.data.canUserAccessProduction);
        }

        setPermissions(newPermissions);
        setLoading(false);
      })
      .catch((err) => ParseError(err).then((e) => setError(e.error)));
  }, [userId, tribeId]);

  const handleToggle = (permission: Permission) => () => {
    if (!canEdit) return;

    let newPermissions = [...permissions];
    newPermissions = updatePermission(permissions, permission.id, !permission.value);

    UserApiClient.updateUserPermOnTribe(userId, mapPermissions(newPermissions), tribeId)
      .then((_) => {
        setPermissions(newPermissions);
      })
      .catch((err) => ParseError(err).then((e) => setError(e.error)));
  };

  return (
    <Box sx={{ margin: 1, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {loading && <Box> Loading... </Box>}
      <Typography variant="h6" gutterBottom component="div">
        Permissions
      </Typography>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {permissions.map((permission) => {
          const labelId = `checkbox-list-label-${permission.id}`;

          return (
            <ListItem key={permission.id} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(permission)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={permission.value}
                    disabled={!canEdit}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={permission.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {error && <AlertMessage type="error"> {error} </AlertMessage>}
    </Box>
  );
};

export default UserPermCheckboxList;
