import React, { SVGProps, useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import MainHeader from '../../components/MainHeader';
import InfoCard from '../../components/InfoCard';
import { UserContext } from '../../context/UserContext';

const Home = () => {
  const { authenticatedUser, isLoading } = useContext(UserContext);

  return (
    <Container
      component="main"
      id="main"
      sx={{
        padding: 8,
        paddingTop: 10,
        marginLeft: 0,
        flexGrow: 1,
      }}
    >
      <MainHeader
        title={
          isLoading
            ? 'Loading...'
            : `Welcome, ${authenticatedUser.email} to the Platform Console app`
        }
      >
        <Typography> Here you can request access to the production environment</Typography>
      </MainHeader>

      <Grid container spacing={3}>
        <InfoCard
          title="Read Database Access"
          subheader="Request read only access to the production database"
          buttonText="Request"
          Logo={StorageRoundedIcon as React.FunctionComponent<SVGProps<SVGSVGElement>>}
          width={6}
          path="/requests/create/read_production_database"
        />
      </Grid>
    </Container>
  );
};

export default Home;
