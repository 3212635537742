import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import AlertMessage from '../../components/AlertMessage';
import LegalText from '../../components/legalText';
import { DbApiClient, ParseError, SalesdbRequestsBody } from '../../api';

type NewRequestParams = {
  target: string;
};

const requests: { [key: string]: { target: SalesdbRequestsBody.TargetEnum } } = {
  read_production_database: {
    target: SalesdbRequestsBody.TargetEnum.ReadProductionDatabase,
  },
};

const NewRequest = () => {
  const navigate = useNavigate();
  const { authenticatedUser, isLoading, userPermissions } = useContext(UserContext);
  const { target } = useParams<NewRequestParams>();
  const [error, setError] = React.useState('');

  const [autoApprove, setAutoApprove] = useState(
    userPermissions?.canAllowAccessDatabase ? userPermissions.canAllowAccessDatabase : false
  );
  const [reason, setReason] = useState('');

  useEffect(() => {
    setAutoApprove(
      userPermissions?.canAllowAccessDatabase ? userPermissions.canAllowAccessDatabase : false
    );
  }, [userPermissions.canAllowAccessDatabase]);

  const handleChangeAutoApprove = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAutoApprove(e.target.checked);

  const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setReason(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (target)
      DbApiClient.newRequest({
        target: requests[target].target,
        autoApprove,
        reason,
        validFor: '4h',
      })
        .then((res) => {
          navigate(`/requests/${res.data.ID}`);
        })
        .catch((err) => ParseError(err).then((errObj) => setError(errObj.error)));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 4,
      }}
    >
      <Paper sx={{ p: 2 }}>
        {error && <AlertMessage type="error"> {error} </AlertMessage>}
        {isLoading ? (
          'Loading...'
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
            <Typography variant="h2">{authenticatedUser.email} &nbsp;</Typography>
            <Typography variant="h5">you are requesting access to &nbsp;</Typography>
            <Typography variant="h2">{target && requests[target].target}</Typography>
          </Box>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            id="reason"
            multiline
            placeholder="Why you need or want this access?"
            fullWidth
            autoFocus
            sx={{ py: 2 }}
            onChange={handleChangeReason}
          />
          <Paper sx={{ p: 2 }}>
            <Typography variant="h3">Please read it carefully:</Typography>
            <Box>
              <LegalText />
            </Box>
          </Paper>

          {userPermissions.canAllowAccessDatabase && (
            <FormControlLabel
              label="Auto Approve request?"
              control={
                <Checkbox
                  checked={autoApprove}
                  onChange={handleChangeAutoApprove}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          )}
          <Button
            variant="contained"
            color="warning"
            sx={{
              marginTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
            }}
            type="submit"
          >
            I am aware and I will be careful
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default NewRequest;
