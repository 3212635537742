import React from 'react';
import { useQuery } from 'react-query';
import { Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { InlineResponse2005, TribeApiClient } from '../../api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Users = () => {
  const { data, status } = useQuery<InlineResponse2005>({
    queryKey: ['getTribes'],
    queryFn: () => TribeApiClient.getTribes(),
    enabled: true,
    retry: 3,
    retryDelay: (attempt) => attempt * 1000, // linear backoff
    refetchInterval: 300000, // 5 min
    refetchIntervalInBackground: true,
  });
  const navigate = useNavigate();

  if (data?.data.tribes) {
    return (
      <Container
        component="main"
        id="main"
        sx={{
          padding: 8,
          paddingTop: 10,
          marginLeft: 0,
          flexGrow: 1,
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Tribes</StyledTableCell>
                <StyledTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.tribes.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.tribeName}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Button size="small" onClick={() => navigate(`/tribes/${row.id}`)}>
                      View
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
  return (
    <div className="App">
      {status === 'error' && <p>Error fetching data</p>}
      {status === 'loading' && <p>Fetching data...</p>}
      {status === 'success' && <p>List is empty.</p>}
    </div>
  );
};

export default Users;
