import { Card, Box, CardContent, Typography, Divider, CardActions, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AlertMessage from '../../components/AlertMessage';
import UseAccessButton from './use_access';
import { AwsApiClient, AWSAccessRequest, ParseError } from '../../api';

const AwsRequestId = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const [request, setRequest] = useState<AWSAccessRequest | undefined>(undefined);

  const { tribeID, id } = useParams<{ tribeID: string; id: string }>();

  useEffect(() => {
    if (!tribeID || !id) return;
    AwsApiClient.getAccessRequest(+tribeID, +id)
      .then((res) => {
        setRequest(res.data);
      })
      .catch((err) => ParseError(err).then((e) => setError(e.error)));
    setLoading(false);
  }, [tribeID, id]);

  return (
    <Box sx={{ width: 'auto', p: 4 }}>
      {loading ? (
        <Box> Loading </Box>
      ) : (
        <>
          {error && <AlertMessage type="error"> {error} </AlertMessage>}
          {tribeID && request && (
            <Card sx={{ minWidth: '400px' }}>
              <CardContent>
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <Typography variant="h5" component="div">
                    {request.target_tribe.tribeName}
                  </Typography>
                  <Typography variant="overline" color="text.secondary">
                    AWS Production Access
                  </Typography>
                </Box>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Typography variant="body2">Status: {request.status}</Typography>
                <Typography variant="body2" gutterBottom>
                  Access request by: {request.user.email}
                </Typography>
                <Typography variant="body2">Valid for: {request.valid_for}</Typography>
                <Box sx={{ py: 2 }}>Reason: {request.reason}</Box>
              </CardContent>
              <CardActions>
                {!request.approved && (
                  <>
                    <Button
                      size="small"
                      color="success"
                      onClick={() => {
                        setLoading(true);
                        AwsApiClient.updateAWSRequest({ approved: true }, +tribeID, request.ID)
                          .then((res) => setRequest(res.data))
                          .catch((err) => ParseError(err).then((errObj) => setError(errObj.error)));
                        setLoading(false);
                      }}
                    >
                      Approve
                    </Button>
                    <Button size="small" color="error" disabled>
                      Delete
                    </Button>
                  </>
                )}
                <UseAccessButton request={request} showUnapproved={false} />
              </CardActions>
            </Card>
          )}
        </>
      )}
    </Box>
  );
};

export default AwsRequestId;
