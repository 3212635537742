// tslint:disable
/**
 * EposNow Platform Console
 * Service for managing tenants, and for requesting temporary access to SalesSQL database and AWS production accounts.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: platform@eposnow.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

/* eslint-disable */

import { Configuration } from './configuration';

// const BASE_PATH = "https://platform.eposnow.io".replace(/\/+$/, "");
const BASE_PATH = '';

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration;

  constructor(configuration?: Configuration, protected basePath: string = BASE_PATH) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
      return;
    }
    this.configuration = {};
    this.basePath = '';
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

const parseRecordToSequenceOfPairs = function (obj: Record<string, any>): [string, any][] {
  const result: [string, any][] = [];

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => result.push([key, item]));
    } else {
      result.push([key, value]);
    }
  }

  return result;
};

/**
 *
 * @export
 * @interface AWSAccessCredentials
 */
export interface AWSAccessCredentials {
  /**
   *
   * @type {string}
   * @memberof AWSAccessCredentials
   */
  accessKeyId: string;
  /**
   *
   * @type {string}
   * @memberof AWSAccessCredentials
   */
  secretAccessKey: string;
  /**
   *
   * @type {string}
   * @memberof AWSAccessCredentials
   */
  sessionToken: string;
}
/**
 *
 * @export
 * @interface AWSAccessRequest
 */
export interface AWSAccessRequest {
  /**
   *
   * @type {number}
   * @memberof AWSAccessRequest
   */
  ID: number;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  CreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  UpdatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  DeletedAt?: string;
  /**
   *
   * @type {number}
   * @memberof AWSAccessRequest
   */
  user_id: number;
  /**
   *
   * @type {User}
   * @memberof AWSAccessRequest
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  accepted_by: string;
  /**
   *
   * @type {number}
   * @memberof AWSAccessRequest
   */
  target_tribe_id: number;
  /**
   *
   * @type {Tribe}
   * @memberof AWSAccessRequest
   */
  target_tribe: Tribe;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  valid_for: string;
  /**
   *
   * @type {Date}
   * @memberof AWSAccessRequest
   */
  valid_until: Date;
  /**
   *
   * @type {boolean}
   * @memberof AWSAccessRequest
   */
  approved: boolean;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  status: AWSAccessRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof AWSAccessRequest
   */
  reason: string;
}

/**
 * @export
 * @namespace AWSAccessRequest
 */
export namespace AWSAccessRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Requested = <any>'Requested',
  }
}
/**
 *
 * @export
 * @interface AWSProductionAccessProfile
 */
export interface AWSProductionAccessProfile {
  /**
   *
   * @type {number}
   * @memberof AWSProductionAccessProfile
   */
  ID: number;
  /**
   *
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  CreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  UpdatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  DeletedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  name: string;
  /**
   * AWS IAM role ARN.
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof AWSProductionAccessProfile
   */
  roleAlias: string;
  /**
   *
   * @type {number}
   * @memberof AWSProductionAccessProfile
   */
  tribe_id: number;
  /**
   *
   * @type {Tribe}
   * @memberof AWSProductionAccessProfile
   */
  tribe: Tribe;
}
/**
 *
 * @export
 * @interface AccessRequestsAccessReqIDBody
 */
export interface AccessRequestsAccessReqIDBody {
  /**
   *
   * @type {boolean}
   * @memberof AccessRequestsAccessReqIDBody
   */
  approved: boolean;
}
/**
 *
 * @export
 * @interface AccessRequestsConsoleBody
 */
export interface AccessRequestsConsoleBody extends AWSAccessCredentials {}
/**
 *
 * @export
 * @interface BadRequestError
 */
export interface BadRequestError extends ErrorModel {}
/**
 *
 * @export
 * @interface CreateAWSAccessRequest
 */
export interface CreateAWSAccessRequest {
  /**
   *
   * @type {number}
   * @memberof CreateAWSAccessRequest
   */
  tribe_id: number;
  /**
   *
   * @type {string}
   * @memberof CreateAWSAccessRequest
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof CreateAWSAccessRequest
   */
  validFor: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateAWSAccessRequest
   */
  autoApprove: boolean;
}
/**
 *
 * @export
 * @interface DBAccessCredentials
 */
export interface DBAccessCredentials {
  /**
   *
   * @type {string}
   * @memberof DBAccessCredentials
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof DBAccessCredentials
   */
  databasePassword: string;
  /**
   *
   * @type {string}
   * @memberof DBAccessCredentials
   */
  windowsPassword: string;
}
/**
 *
 * @export
 * @interface DBAccessRequest
 */
export interface DBAccessRequest {
  /**
   *
   * @type {number}
   * @memberof DBAccessRequest
   */
  ID: number;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  CreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  UpdatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  DeletedAt?: string;
  /**
   *
   * @type {number}
   * @memberof DBAccessRequest
   */
  UserID: number;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  AcceptedBy: string;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  Target: DBAccessRequest.TargetEnum;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  ValidFor: string;
  /**
   *
   * @type {Date}
   * @memberof DBAccessRequest
   */
  ValidUntil: Date;
  /**
   *
   * @type {boolean}
   * @memberof DBAccessRequest
   */
  Approved: boolean;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  Status: DBAccessRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof DBAccessRequest
   */
  Reason: string;
  /**
   *
   * @type {User}
   * @memberof DBAccessRequest
   */
  User: User;
}

/**
 * @export
 * @namespace DBAccessRequest
 */
export namespace DBAccessRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum TargetEnum {
    ReadProductionDatabase = <any>'read production database',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Requested = <any>'Requested',
    Approved = <any>'Approved',
    Expiring = <any>'Expiring',
    Expired = <any>'Expired',
  }
}
/**
 *
 * @export
 * @interface DBPermissions
 */
export interface DBPermissions {
  /**
   *
   * @type {boolean}
   * @memberof DBPermissions
   */
  canRequestReadProductionDatabase: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DBPermissions
   */
  canRequestWriteProductionDatabase: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DBPermissions
   */
  canAllowAccessDatabase: boolean;
}
/**
 *
 * @export
 * @interface DBUserPermissions
 */
export interface DBUserPermissions {
  /**
   *
   * @type {User}
   * @memberof DBUserPermissions
   */
  user: User;
  /**
   *
   * @type {DBPermissions}
   * @memberof DBUserPermissions
   */
  permissions: DBPermissions;
}
/**
 *
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
  /**
   * Error message
   * @type {string}
   * @memberof ErrorModel
   */
  error: string;
}
/**
 *
 * @export
 * @interface ForbiddenError
 */
export interface ForbiddenError extends ErrorModel {}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {Array<User>}
   * @memberof InlineResponse200
   */
  data: Array<User>;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {DBUserPermissions}
   * @memberof InlineResponse2001
   */
  data: DBUserPermissions;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2001
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
  /**
   *
   * @type {AWSAccessCredentials}
   * @memberof InlineResponse20010
   */
  data: AWSAccessCredentials;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20010
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
  /**
   *
   * @type {Array<AWSProductionAccessProfile>}
   * @memberof InlineResponse20011
   */
  data: Array<AWSProductionAccessProfile>;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20011
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
  /**
   *
   * @type {Array<AWSAccessRequest>}
   * @memberof InlineResponse20012
   */
  data: Array<AWSAccessRequest>;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20012
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse20013
   */
  data: string;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20013
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
  /**
   *
   * @type {InlineResponse20014Data}
   * @memberof InlineResponse20014
   */
  data: InlineResponse20014Data;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20014
   */
  code: number;
  /**
   *
   * @type {Pagination}
   * @memberof InlineResponse20014
   */
  pagination: Pagination;
}
/**
 *
 * @export
 * @interface InlineResponse20014Data
 */
export interface InlineResponse20014Data {
  /**
   *
   * @type {Array<DBAccessRequest>}
   * @memberof InlineResponse20014Data
   */
  requests?: Array<DBAccessRequest>;
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
  /**
   *
   * @type {DBAccessRequest}
   * @memberof InlineResponse20015
   */
  data: DBAccessRequest;
  /**
   *
   * @type {number}
   * @memberof InlineResponse20015
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
  /**
   *
   * @type {DBAccessCredentials}
   * @memberof InlineResponse20016
   */
  data: DBAccessCredentials;
  /**
   *
   * @type {string}
   * @memberof InlineResponse20016
   */
  message: string;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {Array<User>}
   * @memberof InlineResponse2002
   */
  data: Array<User>;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {User}
   * @memberof InlineResponse2003
   */
  data: User;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2003
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {TribePermissions}
   * @memberof InlineResponse2004
   */
  data: TribePermissions;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {InlineResponse2005Data}
   * @memberof InlineResponse2005
   */
  data: InlineResponse2005Data;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2005
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse2005Data
 */
export interface InlineResponse2005Data {
  /**
   *
   * @type {Array<Tribe>}
   * @memberof InlineResponse2005Data
   */
  tribes: Array<Tribe>;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {InlineResponse2006Data}
   * @memberof InlineResponse2006
   */
  data: InlineResponse2006Data;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006
   */
  code: number;
  /**
   *
   * @type {InlineResponse2006Pagination}
   * @memberof InlineResponse2006
   */
  pagination: InlineResponse2006Pagination;
}
/**
 *
 * @export
 * @interface InlineResponse2006Data
 */
export interface InlineResponse2006Data {
  /**
   *
   * @type {Tribe & any}
   * @memberof InlineResponse2006Data
   */
  tribe: Tribe & any;
}
/**
 *
 * @export
 * @interface InlineResponse2006Pagination
 */
export interface InlineResponse2006Pagination {
  /**
   *
   * @type {Pagination}
   * @memberof InlineResponse2006Pagination
   */
  owner: Pagination;
  /**
   *
   * @type {Pagination}
   * @memberof InlineResponse2006Pagination
   */
  developer: Pagination;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   *
   * @type {InlineResponse2007Data}
   * @memberof InlineResponse2007
   */
  data: InlineResponse2007Data;
  /**
   *
   * @type {InlineResponse2006Pagination}
   * @memberof InlineResponse2007
   */
  pagination: InlineResponse2006Pagination;
}
/**
 *
 * @export
 * @interface InlineResponse2007Data
 */
export interface InlineResponse2007Data {
  /**
   *
   * @type {Array<User>}
   * @memberof InlineResponse2007Data
   */
  owner: Array<User>;
  /**
   *
   * @type {Array<User>}
   * @memberof InlineResponse2007Data
   */
  developer: Array<User>;
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
  /**
   *
   * @type {AWSAccessRequest}
   * @memberof InlineResponse2008
   */
  data: AWSAccessRequest;
  /**
   * Accepted
   * @type {number}
   * @memberof InlineResponse2008
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
  /**
   *
   * @type {AWSAccessRequest}
   * @memberof InlineResponse2009
   */
  data: AWSAccessRequest;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2009
   */
  code: number;
}
/**
 *
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
  /**
   *
   * @type {DBAccessRequest}
   * @memberof InlineResponse201
   */
  data: DBAccessRequest;
  /**
   *
   * @type {string}
   * @memberof InlineResponse201
   */
  message: string;
}
/**
 *
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse500
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof InlineResponse500
   */
  message: string;
}
/**
 *
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError extends ErrorModel {}
/**
 *
 * @export
 * @interface NotImplementedError
 */
export interface NotImplementedError extends ErrorModel {}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  total_records: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  current_page: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  total_pages: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  next_page: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  prev_page: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  size: number;
}
/**
 *
 * @export
 * @interface RequestsIdBody
 */
export interface RequestsIdBody {
  /**
   *
   * @type {boolean}
   * @memberof RequestsIdBody
   */
  approved?: boolean;
  /**
   *
   * @type {string}
   * @memberof RequestsIdBody
   */
  status?: RequestsIdBody.StatusEnum;
}

/**
 * @export
 * @namespace RequestsIdBody
 */
export namespace RequestsIdBody {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Requested = <any>'Requested',
    Approved = <any>'Approved',
    Expiring = <any>'Expiring',
    Expired = <any>'Expired',
  }
}
/**
 *
 * @export
 * @interface SAMLAssertion
 */
export interface SAMLAssertion {
  /**
   *
   * @type {string}
   * @memberof SAMLAssertion
   */
  SAMLResponse: string;
  /**
   *
   * @type {string}
   * @memberof SAMLAssertion
   */
  RelayState: string;
}
/**
 *
 * @export
 * @interface SalesdbRequestsBody
 */
export interface SalesdbRequestsBody {
  /**
   *
   * @type {string}
   * @memberof SalesdbRequestsBody
   */
  target?: SalesdbRequestsBody.TargetEnum;
  /**
   *
   * @type {string}
   * @memberof SalesdbRequestsBody
   */
  validFor?: string;
  /**
   *
   * @type {boolean}
   * @memberof SalesdbRequestsBody
   */
  autoApprove?: boolean;
  /**
   *
   * @type {string}
   * @memberof SalesdbRequestsBody
   */
  reason?: string;
}

/**
 * @export
 * @namespace SalesdbRequestsBody
 */
export namespace SalesdbRequestsBody {
  /**
   * @export
   * @enum {string}
   */
  export enum TargetEnum {
    ReadProductionDatabase = <any>'read production database',
  }
}
/**
 *
 * @export
 * @interface Tribe
 */
export interface Tribe {
  /**
   *
   * @type {number}
   * @memberof Tribe
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Tribe
   */
  tribeName: Tribe.TribeNameEnum;
  /**
   *
   * @type {string}
   * @memberof Tribe
   */
  awsGroupID: string;
  /**
   *
   * @type {Array<User>}
   * @memberof Tribe
   */
  users: Array<User>;
  /**
   *
   * @type {number}
   * @memberof Tribe
   */
  TribeUserID: number;
  /**
   *
   * @type {string}
   * @memberof Tribe
   */
  Level: Tribe.LevelEnum;
  /**
   *
   * @type {boolean}
   * @memberof Tribe
   */
  CanUserAccessProduction: boolean;
}

/**
 * @export
 * @namespace Tribe
 */
export namespace Tribe {
  /**
   * @export
   * @enum {string}
   */
  export enum TribeNameEnum {
    Dbsuperuser = <any>'dbsuperuser',
    Platform = <any>'platform',
    Money = <any>'money',
    Business = <any>'business',
    Data = <any>'data',
    Pos = <any>'pos',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LevelEnum {
    Developer = <any>'developer',
    Owner = <any>'owner',
  }
}
/**
 *
 * @export
 * @interface TribeIDMembersBody
 */
export interface TribeIDMembersBody {
  /**
   *
   * @type {string}
   * @memberof TribeIDMembersBody
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TribeIDMembersBody
   */
  level: TribeIDMembersBody.LevelEnum;
}

/**
 * @export
 * @namespace TribeIDMembersBody
 */
export namespace TribeIDMembersBody {
  /**
   * @export
   * @enum {string}
   */
  export enum LevelEnum {
    Developer = <any>'developer',
    Owner = <any>'owner',
  }
}
/**
 *
 * @export
 * @interface TribePermissions
 */
export interface TribePermissions {
  /**
   *
   * @type {boolean}
   * @memberof TribePermissions
   */
  canUserManageTribe: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TribePermissions
   */
  canUserAccessProduction: boolean;
}
/**
 *
 * @export
 * @interface UnauthorizedError
 */
export interface UnauthorizedError extends ErrorModel {}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {Array<Tribe>}
   * @memberof User
   */
  tribes?: Array<Tribe>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  role: User.RoleEnum;
}

/**
 * @export
 * @namespace User
 */
export namespace User {
  /**
   * @export
   * @enum {string}
   */
  export enum RoleEnum {
    Administrator = <any>'administrator',
    User = <any>'user',
    Guest = <any>'guest',
  }
}
/**
 *
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  level: string;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  tribe: string;
}
/**
 *
 * @export
 */
export type UsersDetails = Array<UserDetails>;
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * It checks if there is a current valid SAML session. If yes, it redirects to the URL passed in the query parameters. If not, it starts SAML authentication process (redirect to Identity Provider /saml/assertion/_*).
     * @summary This endpoint is called to avoid CORS error when SAML middleware returns opaqueredirect response, to start SAML login.
     * @param {string} url URL to redirect to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    redirectOnSAMLLogin(url: string, options: any = {}): FetchArgs {
      // verify required parameter 'url' is not null or undefined
      if (url === null || url === undefined) {
        throw new RequiredError(
          'url',
          'Required parameter url was null or undefined when calling redirectOnSAMLLogin.'
        );
      }
      const localVarPath = `/auth-redirect`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (url !== undefined) {
        localVarQueryParameter['url'] = url;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * - SAML Assertion (SAMLResponse), generated by the IdP, and relay state (RelayState) in the request body. The SAMLResponse has user details encoded. - SP validates, using the IdP metadata (its certificate included), that the SAMLResponse is indeed from where we would expect, and also validates the RelayState, using it for the client's final redirect. SP will respond with redirect status code, and response headers Set-Cookie, to store the \"token\" cookie in the browser (which is the authentication session JWT), and Location to URL encoded in RelayState now received in the POST request.
     * @summary Validate SAML Assertion (SAMLResponse)
     * @param {string} SAMLResponse
     * @param {string} RelayState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sAMLACS(SAMLResponse: string, RelayState: string, options: any = {}): FetchArgs {
      // verify required parameter 'SAMLResponse' is not null or undefined
      if (SAMLResponse === null || SAMLResponse === undefined) {
        throw new RequiredError(
          'SAMLResponse',
          'Required parameter SAMLResponse was null or undefined when calling sAMLACS.'
        );
      }
      // verify required parameter 'RelayState' is not null or undefined
      if (RelayState === null || RelayState === undefined) {
        throw new RequiredError(
          'RelayState',
          'Required parameter RelayState was null or undefined when calling sAMLACS.'
        );
      }
      const localVarPath = `/saml/acs`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (SAMLResponse !== undefined) {
        localVarFormParams.set('SAMLResponse', SAMLResponse as any);
      }

      if (RelayState !== undefined) {
        localVarFormParams.set('RelayState', RelayState as any);
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  return {
    /**
     * It checks if there is a current valid SAML session. If yes, it redirects to the URL passed in the query parameters. If not, it starts SAML authentication process (redirect to Identity Provider /saml/assertion/_*).
     * @summary This endpoint is called to avoid CORS error when SAML middleware returns opaqueredirect response, to start SAML login.
     * @param {string} url URL to redirect to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    redirectOnSAMLLogin(url: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = AuthApiFetchParamCreator(configuration).redirectOnSAMLLogin(
        url,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * - SAML Assertion (SAMLResponse), generated by the IdP, and relay state (RelayState) in the request body. The SAMLResponse has user details encoded. - SP validates, using the IdP metadata (its certificate included), that the SAMLResponse is indeed from where we would expect, and also validates the RelayState, using it for the client's final redirect. SP will respond with redirect status code, and response headers Set-Cookie, to store the \"token\" cookie in the browser (which is the authentication session JWT), and Location to URL encoded in RelayState now received in the POST request.
     * @summary Validate SAML Assertion (SAMLResponse)
     * @param {string} SAMLResponse
     * @param {string} RelayState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sAMLACS(
      SAMLResponse: string,
      RelayState: string,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = AuthApiFetchParamCreator(configuration).sAMLACS(
        SAMLResponse,
        RelayState,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * It checks if there is a current valid SAML session. If yes, it redirects to the URL passed in the query parameters. If not, it starts SAML authentication process (redirect to Identity Provider /saml/assertion/_*).
     * @summary This endpoint is called to avoid CORS error when SAML middleware returns opaqueredirect response, to start SAML login.
     * @param {string} url URL to redirect to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    redirectOnSAMLLogin(url: string, options?: any) {
      return AuthApiFp(configuration).redirectOnSAMLLogin(url, options)(basePath);
    },
    /**
     * - SAML Assertion (SAMLResponse), generated by the IdP, and relay state (RelayState) in the request body. The SAMLResponse has user details encoded. - SP validates, using the IdP metadata (its certificate included), that the SAMLResponse is indeed from where we would expect, and also validates the RelayState, using it for the client's final redirect. SP will respond with redirect status code, and response headers Set-Cookie, to store the \"token\" cookie in the browser (which is the authentication session JWT), and Location to URL encoded in RelayState now received in the POST request.
     * @summary Validate SAML Assertion (SAMLResponse)
     * @param {string} SAMLResponse
     * @param {string} RelayState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sAMLACS(SAMLResponse: string, RelayState: string, options?: any) {
      return AuthApiFp(configuration).sAMLACS(SAMLResponse, RelayState, options)(basePath);
    },
  };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
  /**
   * It checks if there is a current valid SAML session. If yes, it redirects to the URL passed in the query parameters. If not, it starts SAML authentication process (redirect to Identity Provider /saml/assertion/_*).
   * @summary This endpoint is called to avoid CORS error when SAML middleware returns opaqueredirect response, to start SAML login.
   * @param {string} url URL to redirect to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  redirectOnSAMLLogin(url: string, options?: any): Promise<{}>;

  /**
   * - SAML Assertion (SAMLResponse), generated by the IdP, and relay state (RelayState) in the request body. The SAMLResponse has user details encoded. - SP validates, using the IdP metadata (its certificate included), that the SAMLResponse is indeed from where we would expect, and also validates the RelayState, using it for the client's final redirect. SP will respond with redirect status code, and response headers Set-Cookie, to store the \"token\" cookie in the browser (which is the authentication session JWT), and Location to URL encoded in RelayState now received in the POST request.
   * @summary Validate SAML Assertion (SAMLResponse)
   * @param {string} SAMLResponse
   * @param {string} RelayState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  sAMLACS(SAMLResponse: string, RelayState: string, options?: any): Promise<{}>;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
  /**
   * It checks if there is a current valid SAML session. If yes, it redirects to the URL passed in the query parameters. If not, it starts SAML authentication process (redirect to Identity Provider /saml/assertion/_*).
   * @summary This endpoint is called to avoid CORS error when SAML middleware returns opaqueredirect response, to start SAML login.
   * @param {string} url URL to redirect to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public redirectOnSAMLLogin(url: string, options?: any) {
    return AuthApiFp(this.configuration).redirectOnSAMLLogin(url, options)(this.basePath);
  }

  /**
   * - SAML Assertion (SAMLResponse), generated by the IdP, and relay state (RelayState) in the request body. The SAMLResponse has user details encoded. - SP validates, using the IdP metadata (its certificate included), that the SAMLResponse is indeed from where we would expect, and also validates the RelayState, using it for the client's final redirect. SP will respond with redirect status code, and response headers Set-Cookie, to store the \"token\" cookie in the browser (which is the authentication session JWT), and Location to URL encoded in RelayState now received in the POST request.
   * @summary Validate SAML Assertion (SAMLResponse)
   * @param {string} SAMLResponse
   * @param {string} RelayState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sAMLACS(SAMLResponse: string, RelayState: string, options?: any) {
    return AuthApiFp(this.configuration).sAMLACS(SAMLResponse, RelayState, options)(this.basePath);
  }
}
/**
 * AwsApi - fetch parameter creator
 * @export
 */
export const AwsApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling createAWSAccessRequest.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling createAWSAccessRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'CreateAWSAccessRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * From AWS credentials passed as arguments, returns production account AWS console access URL with sign-in token as a query parameter.
     * @summary Generate URL with sign-in token.
     * @param {AccessRequestsConsoleBody} body AWS Access credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSConsoleURL(body: AccessRequestsConsoleBody, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling createAWSConsoleURL.'
        );
      }
      const localVarPath = `/api/aws/access_requests/console`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'AccessRequestsConsoleBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(
      id: number,
      accessReqID: number,
      accessID: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getAccessCredentials.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling getAccessCredentials.'
        );
      }
      // verify required parameter 'accessID' is not null or undefined
      if (accessID === null || accessID === undefined) {
        throw new RequiredError(
          'accessID',
          'Required parameter accessID was null or undefined when calling getAccessCredentials.'
        );
      }
      const localVarPath =
        `/api/tribes/{id}/aws/access_requests/{accessReqID}/credentials/{accessID}`
          .replace(`{${'id'}}`, encodeURIComponent(String(id)))
          .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)))
          .replace(`{${'accessID'}}`, encodeURIComponent(String(accessID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(id: number, accessReqID: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getAccessRequest.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling getAccessRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests/{accessReqID}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options: any = {}): FetchArgs {
      const localVarPath = `/api/aws/access_requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getProductionAccesses.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/production_accesses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getUserPermOnTribes.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateAWSRequest.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateAWSRequest.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling updateAWSRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests/{accessReqID}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'AccessRequestsAccessReqIDBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateUserPermOnTribe.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'TribePermissions' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AwsApi - functional programming interface
 * @export
 */
export const AwsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(
      body: CreateAWSAccessRequest,
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2008> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).createAWSAccessRequest(
        body,
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * From AWS credentials passed as arguments, returns production account AWS console access URL with sign-in token as a query parameter.
     * @summary Generate URL with sign-in token.
     * @param {AccessRequestsConsoleBody} body AWS Access credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSConsoleURL(
      body: AccessRequestsConsoleBody,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20013> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).createAWSConsoleURL(
        body,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(
      id: number,
      accessReqID: number,
      accessID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20010> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).getAccessCredentials(
        id,
        accessReqID,
        accessID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(
      id: number,
      accessReqID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2009> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).getAccessRequest(
        id,
        accessReqID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options?: any): (basePath?: string) => Promise<InlineResponse20012> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).getMyAccessRequests(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20011> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).getProductionAccesses(
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(
      id: number,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2004> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).getUserPermOnTribes(
        id,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2009> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).updateAWSRequest(
        body,
        id,
        accessReqID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = AwsApiFetchParamCreator(configuration).updateUserPermOnTribe(
        id,
        body,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * AwsApi - factory interface
 * @export
 */
export const AwsApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options?: any) {
      return AwsApiFp(configuration).createAWSAccessRequest(body, id, options)(basePath);
    },
    /**
     * From AWS credentials passed as arguments, returns production account AWS console access URL with sign-in token as a query parameter.
     * @summary Generate URL with sign-in token.
     * @param {AccessRequestsConsoleBody} body AWS Access credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSConsoleURL(body: AccessRequestsConsoleBody, options?: any) {
      return AwsApiFp(configuration).createAWSConsoleURL(body, options)(basePath);
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(id: number, accessReqID: number, accessID: number, options?: any) {
      return AwsApiFp(configuration).getAccessCredentials(
        id,
        accessReqID,
        accessID,
        options
      )(basePath);
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(id: number, accessReqID: number, options?: any) {
      return AwsApiFp(configuration).getAccessRequest(id, accessReqID, options)(basePath);
    },
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options?: any) {
      return AwsApiFp(configuration).getMyAccessRequests(options)(basePath);
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options?: any) {
      return AwsApiFp(configuration).getProductionAccesses(id, options)(basePath);
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
      return AwsApiFp(configuration).getUserPermOnTribes(id, tribe_id, options)(basePath);
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options?: any
    ) {
      return AwsApiFp(configuration).updateAWSRequest(body, id, accessReqID, options)(basePath);
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(id: number, body?: TribePermissions, tribe_id?: number, options?: any) {
      return AwsApiFp(configuration).updateUserPermOnTribe(id, body, tribe_id, options)(basePath);
    },
  };
};

/**
 * AwsApi - interface
 * @export
 * @interface AwsApi
 */
export interface AwsApiInterface {
  /**
   * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
   * @summary Create new AWS Access Request.
   * @param {CreateAWSAccessRequest} body AWS Access Request details.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  createAWSAccessRequest(
    body: CreateAWSAccessRequest,
    id: number,
    options?: any
  ): Promise<InlineResponse2008>;

  /**
   * From AWS credentials passed as arguments, returns production account AWS console access URL with sign-in token as a query parameter.
   * @summary Generate URL with sign-in token.
   * @param {AccessRequestsConsoleBody} body AWS Access credentials.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  createAWSConsoleURL(body: AccessRequestsConsoleBody, options?: any): Promise<InlineResponse20013>;

  /**
   * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
   * @summary Get AWS credentials for accessing an AWS production account.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {number} accessID Production Access Profile ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  getAccessCredentials(
    id: number,
    accessReqID: number,
    accessID: number,
    options?: any
  ): Promise<InlineResponse20010>;

  /**
   * Returns aws_access_requests by ID.
   * @summary Get AWS Access Request.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  getAccessRequest(id: number, accessReqID: number, options?: any): Promise<InlineResponse2009>;

  /**
   * Returns user `aws_access_requests` that did not expired yet.
   * @summary Get authenticated user AWS access requests.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  getMyAccessRequests(options?: any): Promise<InlineResponse20012>;

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  getProductionAccesses(id: number, options?: any): Promise<InlineResponse20011>;

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  getUserPermOnTribes(id: number, tribe_id?: number, options?: any): Promise<InlineResponse2004>;

  /**
   * Update the 'Approved' field of the AWS Access Request.
   * @summary Update AWS Access Request.
   * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  updateAWSRequest(
    body: AccessRequestsAccessReqIDBody,
    id: number,
    accessReqID: number,
    options?: any
  ): Promise<InlineResponse2009>;

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApiInterface
   */
  updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ): Promise<{}>;
}

/**
 * AwsApi - object-oriented interface
 * @export
 * @class AwsApi
 * @extends {BaseAPI}
 */
export class AwsApi extends BaseAPI implements AwsApiInterface {
  /**
   * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
   * @summary Create new AWS Access Request.
   * @param {CreateAWSAccessRequest} body AWS Access Request details.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options?: any) {
    return AwsApiFp(this.configuration).createAWSAccessRequest(body, id, options)(this.basePath);
  }

  /**
   * From AWS credentials passed as arguments, returns production account AWS console access URL with sign-in token as a query parameter.
   * @summary Generate URL with sign-in token.
   * @param {AccessRequestsConsoleBody} body AWS Access credentials.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public createAWSConsoleURL(body: AccessRequestsConsoleBody, options?: any) {
    return AwsApiFp(this.configuration).createAWSConsoleURL(body, options)(this.basePath);
  }

  /**
   * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
   * @summary Get AWS credentials for accessing an AWS production account.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {number} accessID Production Access Profile ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public getAccessCredentials(id: number, accessReqID: number, accessID: number, options?: any) {
    return AwsApiFp(this.configuration).getAccessCredentials(
      id,
      accessReqID,
      accessID,
      options
    )(this.basePath);
  }

  /**
   * Returns aws_access_requests by ID.
   * @summary Get AWS Access Request.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public getAccessRequest(id: number, accessReqID: number, options?: any) {
    return AwsApiFp(this.configuration).getAccessRequest(id, accessReqID, options)(this.basePath);
  }

  /**
   * Returns user `aws_access_requests` that did not expired yet.
   * @summary Get authenticated user AWS access requests.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public getMyAccessRequests(options?: any) {
    return AwsApiFp(this.configuration).getMyAccessRequests(options)(this.basePath);
  }

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public getProductionAccesses(id: number, options?: any) {
    return AwsApiFp(this.configuration).getProductionAccesses(id, options)(this.basePath);
  }

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
    return AwsApiFp(this.configuration).getUserPermOnTribes(id, tribe_id, options)(this.basePath);
  }

  /**
   * Update the 'Approved' field of the AWS Access Request.
   * @summary Update AWS Access Request.
   * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public updateAWSRequest(
    body: AccessRequestsAccessReqIDBody,
    id: number,
    accessReqID: number,
    options?: any
  ) {
    return AwsApiFp(this.configuration).updateAWSRequest(
      body,
      id,
      accessReqID,
      options
    )(this.basePath);
  }

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AwsApi
   */
  public updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ) {
    return AwsApiFp(this.configuration).updateUserPermOnTribe(
      id,
      body,
      tribe_id,
      options
    )(this.basePath);
  }
}
/**
 * DbApi - fetch parameter creator
 * @export
 */
export const DbApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new random password for user login, in SQL Server master database, and creates new random password for user in windows bastion host (AWS EC2)
     * @summary Get DB Access Credentials.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatabaseConnectionDetails(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getDatabaseConnectionDetails.'
        );
      }
      const localVarPath = `/api/salesdb/requests/{id}/connection`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get DB Access Request by ID.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequest(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRequest.'
        );
      }
      const localVarPath = `/api/salesdb/requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/api/salesdb/requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (user_id !== undefined) {
        localVarQueryParameter['user_id'] = user_id;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      if (approved !== undefined) {
        localVarQueryParameter['approved'] = approved;
      }

      if (target !== undefined) {
        localVarQueryParameter['target'] = target;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(body: SalesdbRequestsBody, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling newRequest.'
        );
      }
      const localVarPath = `/api/salesdb/requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SalesdbRequestsBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update DB Access Request status.
     * @param {RequestsIdBody} body DB Access Request status update details.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRequest(body: RequestsIdBody, id: number, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateRequest.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateRequest.'
        );
      }
      const localVarPath = `/api/salesdb/requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'RequestsIdBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options: any = {}): FetchArgs {
      const localVarPath = `/api/users/me`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DbApi - functional programming interface
 * @export
 */
export const DbApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new random password for user login, in SQL Server master database, and creates new random password for user in windows bastion host (AWS EC2)
     * @summary Get DB Access Credentials.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatabaseConnectionDetails(
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20016> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).getDatabaseConnectionDetails(
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     *
     * @summary Get DB Access Request by ID.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequest(id: number, options?: any): (basePath?: string) => Promise<InlineResponse20015> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).getRequest(id, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20014> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).getRequests(
        page,
        limit,
        user_id,
        status,
        approved,
        target,
        active,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(
      body: SalesdbRequestsBody,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse201> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).newRequest(body, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     *
     * @summary Update DB Access Request status.
     * @param {RequestsIdBody} body DB Access Request status update details.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRequest(
      body: RequestsIdBody,
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20015> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).updateRequest(
        body,
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any): (basePath?: string) => Promise<InlineResponse2001> {
      const localVarFetchArgs = DbApiFetchParamCreator(configuration).userGetMe(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * DbApi - factory interface
 * @export
 */
export const DbApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Creates a new random password for user login, in SQL Server master database, and creates new random password for user in windows bastion host (AWS EC2)
     * @summary Get DB Access Credentials.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatabaseConnectionDetails(id: number, options?: any) {
      return DbApiFp(configuration).getDatabaseConnectionDetails(id, options)(basePath);
    },
    /**
     *
     * @summary Get DB Access Request by ID.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequest(id: number, options?: any) {
      return DbApiFp(configuration).getRequest(id, options)(basePath);
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options?: any
    ) {
      return DbApiFp(configuration).getRequests(
        page,
        limit,
        user_id,
        status,
        approved,
        target,
        active,
        options
      )(basePath);
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(body: SalesdbRequestsBody, options?: any) {
      return DbApiFp(configuration).newRequest(body, options)(basePath);
    },
    /**
     *
     * @summary Update DB Access Request status.
     * @param {RequestsIdBody} body DB Access Request status update details.
     * @param {number} id DB Access Request ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRequest(body: RequestsIdBody, id: number, options?: any) {
      return DbApiFp(configuration).updateRequest(body, id, options)(basePath);
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any) {
      return DbApiFp(configuration).userGetMe(options)(basePath);
    },
  };
};

/**
 * DbApi - interface
 * @export
 * @interface DbApi
 */
export interface DbApiInterface {
  /**
   * Creates a new random password for user login, in SQL Server master database, and creates new random password for user in windows bastion host (AWS EC2)
   * @summary Get DB Access Credentials.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  getDatabaseConnectionDetails(id: number, options?: any): Promise<InlineResponse20016>;

  /**
   *
   * @summary Get DB Access Request by ID.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  getRequest(id: number, options?: any): Promise<InlineResponse20015>;

  /**
   * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
   * @summary Get DB Access Requests.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [user_id] Filter access requests by user ID.
   * @param {Array<string>} [status] Filter access requests by status.
   * @param {boolean} [approved] Filter access requests by if approved or not.
   * @param {string} [target] Filter access requests by target.
   * @param {boolean} [active] Filter access requests by still being valid or not.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  getRequests(
    page?: number,
    limit?: number,
    user_id?: number,
    status?: Array<string>,
    approved?: boolean,
    target?: string,
    active?: boolean,
    options?: any
  ): Promise<InlineResponse20014>;

  /**
   * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
   * @summary Create DB Access Request.
   * @param {SalesdbRequestsBody} body New DB Access Request details.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  newRequest(body: SalesdbRequestsBody, options?: any): Promise<InlineResponse201>;

  /**
   *
   * @summary Update DB Access Request status.
   * @param {RequestsIdBody} body DB Access Request status update details.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  updateRequest(body: RequestsIdBody, id: number, options?: any): Promise<InlineResponse20015>;

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApiInterface
   */
  userGetMe(options?: any): Promise<InlineResponse2001>;
}

/**
 * DbApi - object-oriented interface
 * @export
 * @class DbApi
 * @extends {BaseAPI}
 */
export class DbApi extends BaseAPI implements DbApiInterface {
  /**
   * Creates a new random password for user login, in SQL Server master database, and creates new random password for user in windows bastion host (AWS EC2)
   * @summary Get DB Access Credentials.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public getDatabaseConnectionDetails(id: number, options?: any) {
    return DbApiFp(this.configuration).getDatabaseConnectionDetails(id, options)(this.basePath);
  }

  /**
   *
   * @summary Get DB Access Request by ID.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public getRequest(id: number, options?: any) {
    return DbApiFp(this.configuration).getRequest(id, options)(this.basePath);
  }

  /**
   * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
   * @summary Get DB Access Requests.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [user_id] Filter access requests by user ID.
   * @param {Array<string>} [status] Filter access requests by status.
   * @param {boolean} [approved] Filter access requests by if approved or not.
   * @param {string} [target] Filter access requests by target.
   * @param {boolean} [active] Filter access requests by still being valid or not.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public getRequests(
    page?: number,
    limit?: number,
    user_id?: number,
    status?: Array<string>,
    approved?: boolean,
    target?: string,
    active?: boolean,
    options?: any
  ) {
    return DbApiFp(this.configuration).getRequests(
      page,
      limit,
      user_id,
      status,
      approved,
      target,
      active,
      options
    )(this.basePath);
  }

  /**
   * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
   * @summary Create DB Access Request.
   * @param {SalesdbRequestsBody} body New DB Access Request details.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public newRequest(body: SalesdbRequestsBody, options?: any) {
    return DbApiFp(this.configuration).newRequest(body, options)(this.basePath);
  }

  /**
   *
   * @summary Update DB Access Request status.
   * @param {RequestsIdBody} body DB Access Request status update details.
   * @param {number} id DB Access Request ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public updateRequest(body: RequestsIdBody, id: number, options?: any) {
    return DbApiFp(this.configuration).updateRequest(body, id, options)(this.basePath);
  }

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbApi
   */
  public userGetMe(options?: any) {
    return DbApiFp(this.configuration).userGetMe(options)(this.basePath);
  }
}
/**
 * GitlabApi - fetch parameter creator
 * @export
 */
export const GitlabApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 1. db_user.role == administrator AND db_user NOT IN request:   - db_user IN tribes => db_user.role updated to \"user\"   - db_user NOT IN ANY tribe => user deleted 2. db_user.role == administrator AND db_user IN request:   - nothing happens 3. user IN request AND user NOT IN db:   - user created in db with role \"administrator\"    This endpoint can only be used by Gitlab.
     * @summary Update platform console users roles, promoting to admin or removing their admin role
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleAdminsList(body: Array<UserDetails>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updatePlatformConsoleAdminsList.'
        );
      }
      const localVarPath = `/auth/update_administrators`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication GitlabTokenHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Gitlab-Token')
            : configuration.apiKey;
        localVarHeaderParameter['Gitlab-Token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'Array&lt;UserDetails&gt;' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * 1. user IN platform console db AND user NOT IN request body:    - user == administrator => all user permissions removed   - user != administrator => user deleted 2. user IN platform console db AND user IN request body:    - user.perm IN db AND user.prem NOT IN request => user permission removed   - user.role == \"guest\" => update to role \"user\"  This endpoint can only be used by Gitlab.
     * @summary Update list of platform console users
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleUsersList(body: Array<UserDetails>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updatePlatformConsoleUsersList.'
        );
      }
      const localVarPath = `/auth/update_users`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication GitlabTokenHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Gitlab-Token')
            : configuration.apiKey;
        localVarHeaderParameter['Gitlab-Token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'Array&lt;UserDetails&gt;' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GitlabApi - functional programming interface
 * @export
 */
export const GitlabApiFp = function (configuration?: Configuration) {
  return {
    /**
     * 1. db_user.role == administrator AND db_user NOT IN request:   - db_user IN tribes => db_user.role updated to \"user\"   - db_user NOT IN ANY tribe => user deleted 2. db_user.role == administrator AND db_user IN request:   - nothing happens 3. user IN request AND user NOT IN db:   - user created in db with role \"administrator\"    This endpoint can only be used by Gitlab.
     * @summary Update platform console users roles, promoting to admin or removing their admin role
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleAdminsList(
      body: Array<UserDetails>,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse200> {
      const localVarFetchArgs = GitlabApiFetchParamCreator(
        configuration
      ).updatePlatformConsoleAdminsList(body, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * 1. user IN platform console db AND user NOT IN request body:    - user == administrator => all user permissions removed   - user != administrator => user deleted 2. user IN platform console db AND user IN request body:    - user.perm IN db AND user.prem NOT IN request => user permission removed   - user.role == \"guest\" => update to role \"user\"  This endpoint can only be used by Gitlab.
     * @summary Update list of platform console users
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleUsersList(
      body: Array<UserDetails>,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse200> {
      const localVarFetchArgs = GitlabApiFetchParamCreator(
        configuration
      ).updatePlatformConsoleUsersList(body, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * GitlabApi - factory interface
 * @export
 */
export const GitlabApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * 1. db_user.role == administrator AND db_user NOT IN request:   - db_user IN tribes => db_user.role updated to \"user\"   - db_user NOT IN ANY tribe => user deleted 2. db_user.role == administrator AND db_user IN request:   - nothing happens 3. user IN request AND user NOT IN db:   - user created in db with role \"administrator\"    This endpoint can only be used by Gitlab.
     * @summary Update platform console users roles, promoting to admin or removing their admin role
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleAdminsList(body: Array<UserDetails>, options?: any) {
      return GitlabApiFp(configuration).updatePlatformConsoleAdminsList(body, options)(basePath);
    },
    /**
     * 1. user IN platform console db AND user NOT IN request body:    - user == administrator => all user permissions removed   - user != administrator => user deleted 2. user IN platform console db AND user IN request body:    - user.perm IN db AND user.prem NOT IN request => user permission removed   - user.role == \"guest\" => update to role \"user\"  This endpoint can only be used by Gitlab.
     * @summary Update list of platform console users
     * @param {Array<UserDetails>} body Users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlatformConsoleUsersList(body: Array<UserDetails>, options?: any) {
      return GitlabApiFp(configuration).updatePlatformConsoleUsersList(body, options)(basePath);
    },
  };
};

/**
 * GitlabApi - interface
 * @export
 * @interface GitlabApi
 */
export interface GitlabApiInterface {
  /**
   * 1. db_user.role == administrator AND db_user NOT IN request:   - db_user IN tribes => db_user.role updated to \"user\"   - db_user NOT IN ANY tribe => user deleted 2. db_user.role == administrator AND db_user IN request:   - nothing happens 3. user IN request AND user NOT IN db:   - user created in db with role \"administrator\"    This endpoint can only be used by Gitlab.
   * @summary Update platform console users roles, promoting to admin or removing their admin role
   * @param {Array<UserDetails>} body Users details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitlabApiInterface
   */
  updatePlatformConsoleAdminsList(
    body: Array<UserDetails>,
    options?: any
  ): Promise<InlineResponse200>;

  /**
   * 1. user IN platform console db AND user NOT IN request body:    - user == administrator => all user permissions removed   - user != administrator => user deleted 2. user IN platform console db AND user IN request body:    - user.perm IN db AND user.prem NOT IN request => user permission removed   - user.role == \"guest\" => update to role \"user\"  This endpoint can only be used by Gitlab.
   * @summary Update list of platform console users
   * @param {Array<UserDetails>} body Users details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitlabApiInterface
   */
  updatePlatformConsoleUsersList(
    body: Array<UserDetails>,
    options?: any
  ): Promise<InlineResponse200>;
}

/**
 * GitlabApi - object-oriented interface
 * @export
 * @class GitlabApi
 * @extends {BaseAPI}
 */
export class GitlabApi extends BaseAPI implements GitlabApiInterface {
  /**
   * 1. db_user.role == administrator AND db_user NOT IN request:   - db_user IN tribes => db_user.role updated to \"user\"   - db_user NOT IN ANY tribe => user deleted 2. db_user.role == administrator AND db_user IN request:   - nothing happens 3. user IN request AND user NOT IN db:   - user created in db with role \"administrator\"    This endpoint can only be used by Gitlab.
   * @summary Update platform console users roles, promoting to admin or removing their admin role
   * @param {Array<UserDetails>} body Users details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitlabApi
   */
  public updatePlatformConsoleAdminsList(body: Array<UserDetails>, options?: any) {
    return GitlabApiFp(this.configuration).updatePlatformConsoleAdminsList(
      body,
      options
    )(this.basePath);
  }

  /**
   * 1. user IN platform console db AND user NOT IN request body:    - user == administrator => all user permissions removed   - user != administrator => user deleted 2. user IN platform console db AND user IN request body:    - user.perm IN db AND user.prem NOT IN request => user permission removed   - user.role == \"guest\" => update to role \"user\"  This endpoint can only be used by Gitlab.
   * @summary Update list of platform console users
   * @param {Array<UserDetails>} body Users details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitlabApi
   */
  public updatePlatformConsoleUsersList(body: Array<UserDetails>, options?: any) {
    return GitlabApiFp(this.configuration).updatePlatformConsoleUsersList(
      body,
      options
    )(this.basePath);
  }
}
/**
 * MyApi - fetch parameter creator
 * @export
 */
export const MyApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options: any = {}): FetchArgs {
      const localVarPath = `/api/aws/access_requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options: any = {}): FetchArgs {
      const localVarPath = `/api/tribes/me`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getProductionAccesses.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/production_accesses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/api/salesdb/requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (user_id !== undefined) {
        localVarQueryParameter['user_id'] = user_id;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      if (approved !== undefined) {
        localVarQueryParameter['approved'] = approved;
      }

      if (target !== undefined) {
        localVarQueryParameter['target'] = target;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(body: SalesdbRequestsBody, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling newRequest.'
        );
      }
      const localVarPath = `/api/salesdb/requests`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SalesdbRequestsBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options: any = {}): FetchArgs {
      const localVarPath = `/api/users/me`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MyApi - functional programming interface
 * @export
 */
export const MyApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options?: any): (basePath?: string) => Promise<InlineResponse20012> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).getMyAccessRequests(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options?: any): (basePath?: string) => Promise<InlineResponse2005> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).getMyTribes(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20011> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).getProductionAccesses(
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20014> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).getRequests(
        page,
        limit,
        user_id,
        status,
        approved,
        target,
        active,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(
      body: SalesdbRequestsBody,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse201> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).newRequest(body, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any): (basePath?: string) => Promise<InlineResponse2001> {
      const localVarFetchArgs = MyApiFetchParamCreator(configuration).userGetMe(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * MyApi - factory interface
 * @export
 */
export const MyApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Returns user `aws_access_requests` that did not expired yet.
     * @summary Get authenticated user AWS access requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyAccessRequests(options?: any) {
      return MyApiFp(configuration).getMyAccessRequests(options)(basePath);
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options?: any) {
      return MyApiFp(configuration).getMyTribes(options)(basePath);
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options?: any) {
      return MyApiFp(configuration).getProductionAccesses(id, options)(basePath);
    },
    /**
     * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
     * @summary Get DB Access Requests.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [user_id] Filter access requests by user ID.
     * @param {Array<string>} [status] Filter access requests by status.
     * @param {boolean} [approved] Filter access requests by if approved or not.
     * @param {string} [target] Filter access requests by target.
     * @param {boolean} [active] Filter access requests by still being valid or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequests(
      page?: number,
      limit?: number,
      user_id?: number,
      status?: Array<string>,
      approved?: boolean,
      target?: string,
      active?: boolean,
      options?: any
    ) {
      return MyApiFp(configuration).getRequests(
        page,
        limit,
        user_id,
        status,
        approved,
        target,
        active,
        options
      )(basePath);
    },
    /**
     * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
     * @summary Create DB Access Request.
     * @param {SalesdbRequestsBody} body New DB Access Request details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newRequest(body: SalesdbRequestsBody, options?: any) {
      return MyApiFp(configuration).newRequest(body, options)(basePath);
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any) {
      return MyApiFp(configuration).userGetMe(options)(basePath);
    },
  };
};

/**
 * MyApi - interface
 * @export
 * @interface MyApi
 */
export interface MyApiInterface {
  /**
   * Returns user `aws_access_requests` that did not expired yet.
   * @summary Get authenticated user AWS access requests.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  getMyAccessRequests(options?: any): Promise<InlineResponse20012>;

  /**
   *
   * @summary Get authenticated user list of tribes.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  getMyTribes(options?: any): Promise<InlineResponse2005>;

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  getProductionAccesses(id: number, options?: any): Promise<InlineResponse20011>;

  /**
   * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
   * @summary Get DB Access Requests.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [user_id] Filter access requests by user ID.
   * @param {Array<string>} [status] Filter access requests by status.
   * @param {boolean} [approved] Filter access requests by if approved or not.
   * @param {string} [target] Filter access requests by target.
   * @param {boolean} [active] Filter access requests by still being valid or not.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  getRequests(
    page?: number,
    limit?: number,
    user_id?: number,
    status?: Array<string>,
    approved?: boolean,
    target?: string,
    active?: boolean,
    options?: any
  ): Promise<InlineResponse20014>;

  /**
   * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
   * @summary Create DB Access Request.
   * @param {SalesdbRequestsBody} body New DB Access Request details.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  newRequest(body: SalesdbRequestsBody, options?: any): Promise<InlineResponse201>;

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  userGetMe(options?: any): Promise<InlineResponse2001>;
}

/**
 * MyApi - object-oriented interface
 * @export
 * @class MyApi
 * @extends {BaseAPI}
 */
export class MyApi extends BaseAPI implements MyApiInterface {
  /**
   * Returns user `aws_access_requests` that did not expired yet.
   * @summary Get authenticated user AWS access requests.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public getMyAccessRequests(options?: any) {
    return MyApiFp(this.configuration).getMyAccessRequests(options)(this.basePath);
  }

  /**
   *
   * @summary Get authenticated user list of tribes.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public getMyTribes(options?: any) {
    return MyApiFp(this.configuration).getMyTribes(options)(this.basePath);
  }

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public getProductionAccesses(id: number, options?: any) {
    return MyApiFp(this.configuration).getProductionAccesses(id, options)(this.basePath);
  }

  /**
   * Returns a list of DB access requests. If authenticated user is administrator, returns requests of any user. Otherwise, returns just authenticated user requests.
   * @summary Get DB Access Requests.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [user_id] Filter access requests by user ID.
   * @param {Array<string>} [status] Filter access requests by status.
   * @param {boolean} [approved] Filter access requests by if approved or not.
   * @param {string} [target] Filter access requests by target.
   * @param {boolean} [active] Filter access requests by still being valid or not.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public getRequests(
    page?: number,
    limit?: number,
    user_id?: number,
    status?: Array<string>,
    approved?: boolean,
    target?: string,
    active?: boolean,
    options?: any
  ) {
    return MyApiFp(this.configuration).getRequests(
      page,
      limit,
      user_id,
      status,
      approved,
      target,
      active,
      options
    )(this.basePath);
  }

  /**
   * Creates `access_request` for authenticated user. Updates the `access_request` to approved if the user selected the \"Auto Approve\" checkbox and is in tribe \"dbsuperuser\"
   * @summary Create DB Access Request.
   * @param {SalesdbRequestsBody} body New DB Access Request details.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public newRequest(body: SalesdbRequestsBody, options?: any) {
    return MyApiFp(this.configuration).newRequest(body, options)(this.basePath);
  }

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public userGetMe(options?: any) {
    return MyApiFp(this.configuration).userGetMe(options)(this.basePath);
  }
}
/**
 * TribeApi - fetch parameter creator
 * @export
 */
export const TribeApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * If authenticated user is \"administrator\" or \"owner\" of the given tribe, it adds given user (got by email addr) to given tribe.
     * @summary Add user to tribe.
     * @param {number} tribeID Tribe ID.
     * @param {TribeIDMembersBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTribeMember(tribeID: number, body?: TribeIDMembersBody, options: any = {}): FetchArgs {
      // verify required parameter 'tribeID' is not null or undefined
      if (tribeID === null || tribeID === undefined) {
        throw new RequiredError(
          'tribeID',
          'Required parameter tribeID was null or undefined when calling addTribeMember.'
        );
      }
      const localVarPath = `/api/tribes/{tribeID}/members`.replace(
        `{${'tribeID'}}`,
        encodeURIComponent(String(tribeID))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'TribeIDMembersBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling createAWSAccessRequest.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling createAWSAccessRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'POST', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'CreateAWSAccessRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(
      id: number,
      accessReqID: number,
      accessID: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getAccessCredentials.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling getAccessCredentials.'
        );
      }
      // verify required parameter 'accessID' is not null or undefined
      if (accessID === null || accessID === undefined) {
        throw new RequiredError(
          'accessID',
          'Required parameter accessID was null or undefined when calling getAccessCredentials.'
        );
      }
      const localVarPath =
        `/api/tribes/{id}/aws/access_requests/{accessReqID}/credentials/{accessID}`
          .replace(`{${'id'}}`, encodeURIComponent(String(id)))
          .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)))
          .replace(`{${'accessID'}}`, encodeURIComponent(String(accessID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(id: number, accessReqID: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getAccessRequest.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling getAccessRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests/{accessReqID}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options: any = {}): FetchArgs {
      const localVarPath = `/api/tribes/me`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getProductionAccesses.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/production_accesses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Besides the object with tribe and its members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.
     * @summary Get object with tribe and its members.
     * @param {number} id Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribe(id: number, page?: number, limit?: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getTribe.'
        );
      }
      const localVarPath = `/api/tribes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Besides the object with tribe members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.  There is the optional query parameter `include_fields` to specify which tribe members levels to return.  Is possible to apply filters to users attributes using query parameters.
     * @summary Get tribe members.
     * @param {number} tribeID Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [id] Filter user by ID.
     * @param {string} [email] Filter user by email address (checks if value present in user email; SQL LIKE function).
     * @param {string} [role] Filter user by role (administrator, user, or guest).
     * @param {string} [level] Filter user by level (owner or developer).
     * @param {Array<string>} [include_fields] Which tribe member level to include in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribeMembers(
      tribeID: number,
      page?: number,
      limit?: number,
      id?: number,
      email?: string,
      role?: string,
      level?: string,
      include_fields?: Array<string>,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'tribeID' is not null or undefined
      if (tribeID === null || tribeID === undefined) {
        throw new RequiredError(
          'tribeID',
          'Required parameter tribeID was null or undefined when calling getTribeMembers.'
        );
      }
      const localVarPath = `/api/tribes/{tribeID}/members`.replace(
        `{${'tribeID'}}`,
        encodeURIComponent(String(tribeID))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (role !== undefined) {
        localVarQueryParameter['role'] = role;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      if (include_fields) {
        localVarQueryParameter['include_fields'] = include_fields;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * If the optional query parameter `can_user_access_production` is passed, the response will be a list of tribes where authenticated user has, or not, access to AWS production account (depending on the value passed in the query parameter). Otherwise, all tribes existent in Platform Console are returned.
     * @summary Get list of tribes.
     * @param {boolean} [can_user_access_production] Flag that tells whether a user can request read-write access to production account on behalf of a tribe member.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribes(can_user_access_production?: boolean, options: any = {}): FetchArgs {
      const localVarPath = `/api/tribes`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (can_user_access_production !== undefined) {
        localVarQueryParameter['can_user_access_production'] = can_user_access_production;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getUserPermOnTribes.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove entry in tribe_users table.
     * @summary Remove user from tribe.
     * @param {number} tribe_id Tribe ID.
     * @param {number} user_id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeTribeMember(tribe_id: number, user_id: number, options: any = {}): FetchArgs {
      // verify required parameter 'tribe_id' is not null or undefined
      if (tribe_id === null || tribe_id === undefined) {
        throw new RequiredError(
          'tribe_id',
          'Required parameter tribe_id was null or undefined when calling removeTribeMember.'
        );
      }
      // verify required parameter 'user_id' is not null or undefined
      if (user_id === null || user_id === undefined) {
        throw new RequiredError(
          'user_id',
          'Required parameter user_id was null or undefined when calling removeTribeMember.'
        );
      }
      const localVarPath = `/api/tribes/{tribe_id}/members/{user_id}`
        .replace(`{${'tribe_id'}}`, encodeURIComponent(String(tribe_id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(user_id)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateAWSRequest.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateAWSRequest.'
        );
      }
      // verify required parameter 'accessReqID' is not null or undefined
      if (accessReqID === null || accessReqID === undefined) {
        throw new RequiredError(
          'accessReqID',
          'Required parameter accessReqID was null or undefined when calling updateAWSRequest.'
        );
      }
      const localVarPath = `/api/tribes/{id}/aws/access_requests/{accessReqID}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'accessReqID'}}`, encodeURIComponent(String(accessReqID)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'AccessRequestsAccessReqIDBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateUserPermOnTribe.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'TribePermissions' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TribeApi - functional programming interface
 * @export
 */
export const TribeApiFp = function (configuration?: Configuration) {
  return {
    /**
     * If authenticated user is \"administrator\" or \"owner\" of the given tribe, it adds given user (got by email addr) to given tribe.
     * @summary Add user to tribe.
     * @param {number} tribeID Tribe ID.
     * @param {TribeIDMembersBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTribeMember(
      tribeID: number,
      body?: TribeIDMembersBody,
      options?: any
    ): (basePath?: string) => Promise<any> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).addTribeMember(
        tribeID,
        body,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(
      body: CreateAWSAccessRequest,
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2008> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).createAWSAccessRequest(
        body,
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(
      id: number,
      accessReqID: number,
      accessID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20010> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getAccessCredentials(
        id,
        accessReqID,
        accessID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(
      id: number,
      accessReqID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2009> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getAccessRequest(
        id,
        accessReqID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options?: any): (basePath?: string) => Promise<InlineResponse2005> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getMyTribes(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(
      id: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse20011> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getProductionAccesses(
        id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Besides the object with tribe and its members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.
     * @summary Get object with tribe and its members.
     * @param {number} id Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribe(
      id: number,
      page?: number,
      limit?: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2006> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getTribe(
        id,
        page,
        limit,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Besides the object with tribe members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.  There is the optional query parameter `include_fields` to specify which tribe members levels to return.  Is possible to apply filters to users attributes using query parameters.
     * @summary Get tribe members.
     * @param {number} tribeID Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [id] Filter user by ID.
     * @param {string} [email] Filter user by email address (checks if value present in user email; SQL LIKE function).
     * @param {string} [role] Filter user by role (administrator, user, or guest).
     * @param {string} [level] Filter user by level (owner or developer).
     * @param {Array<string>} [include_fields] Which tribe member level to include in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribeMembers(
      tribeID: number,
      page?: number,
      limit?: number,
      id?: number,
      email?: string,
      role?: string,
      level?: string,
      include_fields?: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2007> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getTribeMembers(
        tribeID,
        page,
        limit,
        id,
        email,
        role,
        level,
        include_fields,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * If the optional query parameter `can_user_access_production` is passed, the response will be a list of tribes where authenticated user has, or not, access to AWS production account (depending on the value passed in the query parameter). Otherwise, all tribes existent in Platform Console are returned.
     * @summary Get list of tribes.
     * @param {boolean} [can_user_access_production] Flag that tells whether a user can request read-write access to production account on behalf of a tribe member.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribes(
      can_user_access_production?: boolean,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2005> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getTribes(
        can_user_access_production,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(
      id: number,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2004> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).getUserPermOnTribes(
        id,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Remove entry in tribe_users table.
     * @summary Remove user from tribe.
     * @param {number} tribe_id Tribe ID.
     * @param {number} user_id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeTribeMember(
      tribe_id: number,
      user_id: number,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).removeTribeMember(
        tribe_id,
        user_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2009> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).updateAWSRequest(
        body,
        id,
        accessReqID,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = TribeApiFetchParamCreator(configuration).updateUserPermOnTribe(
        id,
        body,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * TribeApi - factory interface
 * @export
 */
export const TribeApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * If authenticated user is \"administrator\" or \"owner\" of the given tribe, it adds given user (got by email addr) to given tribe.
     * @summary Add user to tribe.
     * @param {number} tribeID Tribe ID.
     * @param {TribeIDMembersBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTribeMember(tribeID: number, body?: TribeIDMembersBody, options?: any) {
      return TribeApiFp(configuration).addTribeMember(tribeID, body, options)(basePath);
    },
    /**
     * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
     * @summary Create new AWS Access Request.
     * @param {CreateAWSAccessRequest} body AWS Access Request details.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options?: any) {
      return TribeApiFp(configuration).createAWSAccessRequest(body, id, options)(basePath);
    },
    /**
     * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
     * @summary Get AWS credentials for accessing an AWS production account.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {number} accessID Production Access Profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessCredentials(id: number, accessReqID: number, accessID: number, options?: any) {
      return TribeApiFp(configuration).getAccessCredentials(
        id,
        accessReqID,
        accessID,
        options
      )(basePath);
    },
    /**
     * Returns aws_access_requests by ID.
     * @summary Get AWS Access Request.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessRequest(id: number, accessReqID: number, options?: any) {
      return TribeApiFp(configuration).getAccessRequest(id, accessReqID, options)(basePath);
    },
    /**
     *
     * @summary Get authenticated user list of tribes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyTribes(options?: any) {
      return TribeApiFp(configuration).getMyTribes(options)(basePath);
    },
    /**
     * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
     * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
     * @param {number} id Tribe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionAccesses(id: number, options?: any) {
      return TribeApiFp(configuration).getProductionAccesses(id, options)(basePath);
    },
    /**
     * Besides the object with tribe and its members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.
     * @summary Get object with tribe and its members.
     * @param {number} id Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribe(id: number, page?: number, limit?: number, options?: any) {
      return TribeApiFp(configuration).getTribe(id, page, limit, options)(basePath);
    },
    /**
     * Besides the object with tribe members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.  There is the optional query parameter `include_fields` to specify which tribe members levels to return.  Is possible to apply filters to users attributes using query parameters.
     * @summary Get tribe members.
     * @param {number} tribeID Tribe ID.
     * @param {number} [page] Pagination page number.
     * @param {number} [limit] Pagination page size.
     * @param {number} [id] Filter user by ID.
     * @param {string} [email] Filter user by email address (checks if value present in user email; SQL LIKE function).
     * @param {string} [role] Filter user by role (administrator, user, or guest).
     * @param {string} [level] Filter user by level (owner or developer).
     * @param {Array<string>} [include_fields] Which tribe member level to include in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribeMembers(
      tribeID: number,
      page?: number,
      limit?: number,
      id?: number,
      email?: string,
      role?: string,
      level?: string,
      include_fields?: Array<string>,
      options?: any
    ) {
      return TribeApiFp(configuration).getTribeMembers(
        tribeID,
        page,
        limit,
        id,
        email,
        role,
        level,
        include_fields,
        options
      )(basePath);
    },
    /**
     * If the optional query parameter `can_user_access_production` is passed, the response will be a list of tribes where authenticated user has, or not, access to AWS production account (depending on the value passed in the query parameter). Otherwise, all tribes existent in Platform Console are returned.
     * @summary Get list of tribes.
     * @param {boolean} [can_user_access_production] Flag that tells whether a user can request read-write access to production account on behalf of a tribe member.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTribes(can_user_access_production?: boolean, options?: any) {
      return TribeApiFp(configuration).getTribes(can_user_access_production, options)(basePath);
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
      return TribeApiFp(configuration).getUserPermOnTribes(id, tribe_id, options)(basePath);
    },
    /**
     * Remove entry in tribe_users table.
     * @summary Remove user from tribe.
     * @param {number} tribe_id Tribe ID.
     * @param {number} user_id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeTribeMember(tribe_id: number, user_id: number, options?: any) {
      return TribeApiFp(configuration).removeTribeMember(tribe_id, user_id, options)(basePath);
    },
    /**
     * Update the 'Approved' field of the AWS Access Request.
     * @summary Update AWS Access Request.
     * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
     * @param {number} id Tribe ID
     * @param {number} accessReqID Requested access request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAWSRequest(
      body: AccessRequestsAccessReqIDBody,
      id: number,
      accessReqID: number,
      options?: any
    ) {
      return TribeApiFp(configuration).updateAWSRequest(body, id, accessReqID, options)(basePath);
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(id: number, body?: TribePermissions, tribe_id?: number, options?: any) {
      return TribeApiFp(configuration).updateUserPermOnTribe(id, body, tribe_id, options)(basePath);
    },
  };
};

/**
 * TribeApi - interface
 * @export
 * @interface TribeApi
 */
export interface TribeApiInterface {
  /**
   * If authenticated user is \"administrator\" or \"owner\" of the given tribe, it adds given user (got by email addr) to given tribe.
   * @summary Add user to tribe.
   * @param {number} tribeID Tribe ID.
   * @param {TribeIDMembersBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  addTribeMember(tribeID: number, body?: TribeIDMembersBody, options?: any): Promise<any>;

  /**
   * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
   * @summary Create new AWS Access Request.
   * @param {CreateAWSAccessRequest} body AWS Access Request details.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  createAWSAccessRequest(
    body: CreateAWSAccessRequest,
    id: number,
    options?: any
  ): Promise<InlineResponse2008>;

  /**
   * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
   * @summary Get AWS credentials for accessing an AWS production account.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {number} accessID Production Access Profile ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getAccessCredentials(
    id: number,
    accessReqID: number,
    accessID: number,
    options?: any
  ): Promise<InlineResponse20010>;

  /**
   * Returns aws_access_requests by ID.
   * @summary Get AWS Access Request.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getAccessRequest(id: number, accessReqID: number, options?: any): Promise<InlineResponse2009>;

  /**
   *
   * @summary Get authenticated user list of tribes.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getMyTribes(options?: any): Promise<InlineResponse2005>;

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getProductionAccesses(id: number, options?: any): Promise<InlineResponse20011>;

  /**
   * Besides the object with tribe and its members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.
   * @summary Get object with tribe and its members.
   * @param {number} id Tribe ID.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getTribe(id: number, page?: number, limit?: number, options?: any): Promise<InlineResponse2006>;

  /**
   * Besides the object with tribe members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.  There is the optional query parameter `include_fields` to specify which tribe members levels to return.  Is possible to apply filters to users attributes using query parameters.
   * @summary Get tribe members.
   * @param {number} tribeID Tribe ID.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [id] Filter user by ID.
   * @param {string} [email] Filter user by email address (checks if value present in user email; SQL LIKE function).
   * @param {string} [role] Filter user by role (administrator, user, or guest).
   * @param {string} [level] Filter user by level (owner or developer).
   * @param {Array<string>} [include_fields] Which tribe member level to include in the response body.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getTribeMembers(
    tribeID: number,
    page?: number,
    limit?: number,
    id?: number,
    email?: string,
    role?: string,
    level?: string,
    include_fields?: Array<string>,
    options?: any
  ): Promise<InlineResponse2007>;

  /**
   * If the optional query parameter `can_user_access_production` is passed, the response will be a list of tribes where authenticated user has, or not, access to AWS production account (depending on the value passed in the query parameter). Otherwise, all tribes existent in Platform Console are returned.
   * @summary Get list of tribes.
   * @param {boolean} [can_user_access_production] Flag that tells whether a user can request read-write access to production account on behalf of a tribe member.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getTribes(can_user_access_production?: boolean, options?: any): Promise<InlineResponse2005>;

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  getUserPermOnTribes(id: number, tribe_id?: number, options?: any): Promise<InlineResponse2004>;

  /**
   * Remove entry in tribe_users table.
   * @summary Remove user from tribe.
   * @param {number} tribe_id Tribe ID.
   * @param {number} user_id User ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  removeTribeMember(tribe_id: number, user_id: number, options?: any): Promise<{}>;

  /**
   * Update the 'Approved' field of the AWS Access Request.
   * @summary Update AWS Access Request.
   * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  updateAWSRequest(
    body: AccessRequestsAccessReqIDBody,
    id: number,
    accessReqID: number,
    options?: any
  ): Promise<InlineResponse2009>;

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApiInterface
   */
  updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ): Promise<{}>;
}

/**
 * TribeApi - object-oriented interface
 * @export
 * @class TribeApi
 * @extends {BaseAPI}
 */
export class TribeApi extends BaseAPI implements TribeApiInterface {
  /**
   * If authenticated user is \"administrator\" or \"owner\" of the given tribe, it adds given user (got by email addr) to given tribe.
   * @summary Add user to tribe.
   * @param {number} tribeID Tribe ID.
   * @param {TribeIDMembersBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public addTribeMember(tribeID: number, body?: TribeIDMembersBody, options?: any) {
    return TribeApiFp(this.configuration).addTribeMember(tribeID, body, options)(this.basePath);
  }

  /**
   * `aws_access_request` is created if user can access production accounts as a tribe member (`tribe_users.can_user_access_production`) or is the tribe owner.  `aws_access_request` is approved right away if user checked auto approve checkbox.
   * @summary Create new AWS Access Request.
   * @param {CreateAWSAccessRequest} body AWS Access Request details.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public createAWSAccessRequest(body: CreateAWSAccessRequest, id: number, options?: any) {
    return TribeApiFp(this.configuration).createAWSAccessRequest(body, id, options)(this.basePath);
  }

  /**
   * Checks if `aws_access_request` was approved and did not expired.  If so, gets `aws_production_accesses`, assumes the IAM role in it, and returns AWS credentials (AccessKeyId, SecretAccessKey and SessionToken)
   * @summary Get AWS credentials for accessing an AWS production account.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {number} accessID Production Access Profile ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getAccessCredentials(id: number, accessReqID: number, accessID: number, options?: any) {
    return TribeApiFp(this.configuration).getAccessCredentials(
      id,
      accessReqID,
      accessID,
      options
    )(this.basePath);
  }

  /**
   * Returns aws_access_requests by ID.
   * @summary Get AWS Access Request.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getAccessRequest(id: number, accessReqID: number, options?: any) {
    return TribeApiFp(this.configuration).getAccessRequest(id, accessReqID, options)(this.basePath);
  }

  /**
   *
   * @summary Get authenticated user list of tribes.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getMyTribes(options?: any) {
    return TribeApiFp(this.configuration).getMyTribes(options)(this.basePath);
  }

  /**
   * Returns a list of `aws_production_accesses` for authenticated user in a specific tribe
   * @summary Get all the AWS tribe profiles that can be assumed to get access to AWS production account.
   * @param {number} id Tribe ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getProductionAccesses(id: number, options?: any) {
    return TribeApiFp(this.configuration).getProductionAccesses(id, options)(this.basePath);
  }

  /**
   * Besides the object with tribe and its members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.
   * @summary Get object with tribe and its members.
   * @param {number} id Tribe ID.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getTribe(id: number, page?: number, limit?: number, options?: any) {
    return TribeApiFp(this.configuration).getTribe(id, page, limit, options)(this.basePath);
  }

  /**
   * Besides the object with tribe members, separated by role (\"owner\" and \"developer\"), pagination information is returned in the response body.  There are optional query parameters for pagination.  There is the optional query parameter `include_fields` to specify which tribe members levels to return.  Is possible to apply filters to users attributes using query parameters.
   * @summary Get tribe members.
   * @param {number} tribeID Tribe ID.
   * @param {number} [page] Pagination page number.
   * @param {number} [limit] Pagination page size.
   * @param {number} [id] Filter user by ID.
   * @param {string} [email] Filter user by email address (checks if value present in user email; SQL LIKE function).
   * @param {string} [role] Filter user by role (administrator, user, or guest).
   * @param {string} [level] Filter user by level (owner or developer).
   * @param {Array<string>} [include_fields] Which tribe member level to include in the response body.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getTribeMembers(
    tribeID: number,
    page?: number,
    limit?: number,
    id?: number,
    email?: string,
    role?: string,
    level?: string,
    include_fields?: Array<string>,
    options?: any
  ) {
    return TribeApiFp(this.configuration).getTribeMembers(
      tribeID,
      page,
      limit,
      id,
      email,
      role,
      level,
      include_fields,
      options
    )(this.basePath);
  }

  /**
   * If the optional query parameter `can_user_access_production` is passed, the response will be a list of tribes where authenticated user has, or not, access to AWS production account (depending on the value passed in the query parameter). Otherwise, all tribes existent in Platform Console are returned.
   * @summary Get list of tribes.
   * @param {boolean} [can_user_access_production] Flag that tells whether a user can request read-write access to production account on behalf of a tribe member.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getTribes(can_user_access_production?: boolean, options?: any) {
    return TribeApiFp(this.configuration).getTribes(
      can_user_access_production,
      options
    )(this.basePath);
  }

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
    return TribeApiFp(this.configuration).getUserPermOnTribes(id, tribe_id, options)(this.basePath);
  }

  /**
   * Remove entry in tribe_users table.
   * @summary Remove user from tribe.
   * @param {number} tribe_id Tribe ID.
   * @param {number} user_id User ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public removeTribeMember(tribe_id: number, user_id: number, options?: any) {
    return TribeApiFp(this.configuration).removeTribeMember(
      tribe_id,
      user_id,
      options
    )(this.basePath);
  }

  /**
   * Update the 'Approved' field of the AWS Access Request.
   * @summary Update AWS Access Request.
   * @param {AccessRequestsAccessReqIDBody} body AWS Access Request &#x27;Approved&#x27; flag.
   * @param {number} id Tribe ID
   * @param {number} accessReqID Requested access request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public updateAWSRequest(
    body: AccessRequestsAccessReqIDBody,
    id: number,
    accessReqID: number,
    options?: any
  ) {
    return TribeApiFp(this.configuration).updateAWSRequest(
      body,
      id,
      accessReqID,
      options
    )(this.basePath);
  }

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TribeApi
   */
  public updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ) {
    return TribeApiFp(this.configuration).updateUserPermOnTribe(
      id,
      body,
      tribe_id,
      options
    )(this.basePath);
  }
}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns user by ID, or empty user struct if it doesn't exist.
     * @summary Get user by ID.
     * @param {number} id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getUser.'
        );
      }
      const localVarPath = `/api/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getUserPermOnTribes.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return list of tribe members (of role \"user\" or \"owner\"), each with a list of tribes that they belong to.
     * @summary Get list of all Platform Console users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsers(options: any = {}): FetchArgs {
      const localVarPath = `/api/users`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateUserPermOnTribe.'
        );
      }
      const localVarPath = `/api/users/{id}/permissions`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      if (tribe_id !== undefined) {
        localVarQueryParameter['tribe_id'] = tribe_id;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'TribePermissions' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options: any = {}): FetchArgs {
      const localVarPath = `/api/users/me`;
      const i = localVarPath.indexOf('?');
      const q = i === -1 ? '' : localVarPath.substring(i);
      const p = i === -1 ? localVarPath : localVarPath.substring(0, i);
      const localVarUrlSearchParams = new URLSearchParams(q);
      const localVarRequestOptions = Object.assign(
        { method: 'GET', mode: 'cors', credentials: 'include', redirect: 'manual' },
        options
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication SAMLSessionJWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('token')
            : configuration.apiKey;
        localVarQueryParameter['token'] = localVarApiKeyValue;
      }

      const r = Object.assign(
        {},
        Object.fromEntries(localVarUrlSearchParams ?? new URLSearchParams('')),
        localVarQueryParameter,
        Object.fromEntries(options.query ?? new URLSearchParams(''))
      );
      const transformedR = parseRecordToSequenceOfPairs(r);
      const mergedQ = new URLSearchParams(transformedR);
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: p + (mergedQ.toString() === '' ? '' : '?' + mergedQ.toString()),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Returns user by ID, or empty user struct if it doesn't exist.
     * @summary Get user by ID.
     * @param {number} id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: number, options?: any): (basePath?: string) => Promise<InlineResponse2003> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(id, options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(
      id: number,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<InlineResponse2004> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserPermOnTribes(
        id,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Return list of tribe members (of role \"user\" or \"owner\"), each with a list of tribes that they belong to.
     * @summary Get list of all Platform Console users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsers(options?: any): (basePath?: string) => Promise<InlineResponse2002> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).listUsers(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(
      id: number,
      body?: TribePermissions,
      tribe_id?: number,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateUserPermOnTribe(
        id,
        body,
        tribe_id,
        options
      );
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              throw response;
            }
          }
        );
      };
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any): (basePath?: string) => Promise<InlineResponse2001> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).userGetMe(options);
      return (basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
          (response) => {
            if (response.type === 'opaqueredirect') {
              window.location.href = `/auth-redirect?url=${window.location.pathname}`;
            }
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw response;
            }
          }
        );
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string) {
  return {
    /**
     * Returns user by ID, or empty user struct if it doesn't exist.
     * @summary Get user by ID.
     * @param {number} id User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: number, options?: any) {
      return UserApiFp(configuration).getUser(id, options)(basePath);
    },
    /**
     * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
     * @summary Get user permissions on tribes it is member of.
     * @param {number} id User ID.
     * @param {number} [tribe_id] Tribe ID to get permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
      return UserApiFp(configuration).getUserPermOnTribes(id, tribe_id, options)(basePath);
    },
    /**
     * Return list of tribe members (of role \"user\" or \"owner\"), each with a list of tribes that they belong to.
     * @summary Get list of all Platform Console users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsers(options?: any) {
      return UserApiFp(configuration).listUsers(options)(basePath);
    },
    /**
     * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
     * @summary Update user permissions on tribe.
     * @param {number} id User ID.
     * @param {TribePermissions} [body]
     * @param {number} [tribe_id] Tribe ID to update user permissions on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPermOnTribe(id: number, body?: TribePermissions, tribe_id?: number, options?: any) {
      return UserApiFp(configuration).updateUserPermOnTribe(id, body, tribe_id, options)(basePath);
    },
    /**
     * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
     * @summary Get authenticated user details and database permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetMe(options?: any) {
      return UserApiFp(configuration).userGetMe(options)(basePath);
    },
  };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
  /**
   * Returns user by ID, or empty user struct if it doesn't exist.
   * @summary Get user by ID.
   * @param {number} id User ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  getUser(id: number, options?: any): Promise<InlineResponse2003>;

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  getUserPermOnTribes(id: number, tribe_id?: number, options?: any): Promise<InlineResponse2004>;

  /**
   * Return list of tribe members (of role \"user\" or \"owner\"), each with a list of tribes that they belong to.
   * @summary Get list of all Platform Console users.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  listUsers(options?: any): Promise<InlineResponse2002>;

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ): Promise<{}>;

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  userGetMe(options?: any): Promise<InlineResponse2001>;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
  /**
   * Returns user by ID, or empty user struct if it doesn't exist.
   * @summary Get user by ID.
   * @param {number} id User ID.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(id: number, options?: any) {
    return UserApiFp(this.configuration).getUser(id, options)(this.basePath);
  }

  /**
   * Returns struct that tells if given user can manage the given tribe, and if can access AWS production accounts on behalf of that given tribe.  Is possible to target a single tribe by using the optional filter query parameter `tribe_id`.  **Note:** it is still not implemented returning all user tribes permissions when the filter query parameter is not passed.
   * @summary Get user permissions on tribes it is member of.
   * @param {number} id User ID.
   * @param {number} [tribe_id] Tribe ID to get permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserPermOnTribes(id: number, tribe_id?: number, options?: any) {
    return UserApiFp(this.configuration).getUserPermOnTribes(id, tribe_id, options)(this.basePath);
  }

  /**
   * Return list of tribe members (of role \"user\" or \"owner\"), each with a list of tribes that they belong to.
   * @summary Get list of all Platform Console users.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public listUsers(options?: any) {
    return UserApiFp(this.configuration).listUsers(options)(this.basePath);
  }

  /**
   * If authenticated user is \"administrator\", or \"owner\" of given tribe, it will update the given user permission canUserAccessProduction on behalf of given tribe.
   * @summary Update user permissions on tribe.
   * @param {number} id User ID.
   * @param {TribePermissions} [body]
   * @param {number} [tribe_id] Tribe ID to update user permissions on.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserPermOnTribe(
    id: number,
    body?: TribePermissions,
    tribe_id?: number,
    options?: any
  ) {
    return UserApiFp(this.configuration).updateUserPermOnTribe(
      id,
      body,
      tribe_id,
      options
    )(this.basePath);
  }

  /**
   * Returns authenticated user and a map where key is a Permission in PermissionsStore, and value is whether authenticated user has that permission.
   * @summary Get authenticated user details and database permissions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userGetMe(options?: any) {
    return UserApiFp(this.configuration).userGetMe(options)(this.basePath);
  }
}
